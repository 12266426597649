.transition {
    position: fixed;
    left: 50%;
    transition: all 0.3s ease-out;
    z-index: 2000;
    &.visible {
        top: 5%;
        transform: translateX(-50%) translateY(0);
    }
    &.hidden {
        top: 0;
        transform: translateX(-50%) translateY(-100%);
    }
}

.toast {
    display: flex;
    background: #fff;
    border-radius: 4px;
    height: 48px;
    padding: 14px 20px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    font-size: 14px;
    color: #000000;
    line-height: 20px;
}
.icon {
    height: 20px;
    width: 20px;
}
.children {
    padding-left: 10px;
}
