.modal_wrap {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: 1002;
    display: flex;
    justify-content: center;
    align-items: center;
    .modal {
        position: relative;
        background: #fff;
        padding: 4px 20px 0 20px;
        &_title {
            font-size: 14px;
            text-align: center;
            line-height: 22px;
            text-align: center;
            padding: 16px 0;
            border-bottom: 1px solid #eee;
        }
        &-close {
            position: absolute;
            top: 25px;
            right: 20px;
            cursor: pointer;
            z-index: 100;
            & > i {
                font-size: 12px;
                color: #999;
            }
        }
        &-container {
            padding: 20px 0;
        }
    }
}
