/**
 * NOTE: IMPORTANT
 * 所有的 class 在 .header 子层级下
 */

.header {
    position: fixed;
    height: 60px;
    top: 0;
    left: 0;
    padding: 0 30px;
    font-size: 14px;
    background-color: #000;
    color: #fff;
    width: 100%;
    z-index: 1000;
    min-width: 1280px;
    font-weight: 500;
    &.wenxin {
        padding: 0 16px;
        min-width: auto;
        .nav {
            margin-left: 154px;
            .nav-item:nth-last-of-type(1) {
                margin-right: 0;
            }
        }
    }

    .easydl,
    .data,
    .edge,
    .geely,
    .wenxin {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 30px;
        height: 28px;
        width: 162px;
        object-fit: contain;
    }
    .wenxin {
        width: auto;
        left: 16px;
    }
    .bml {
        position: absolute;
        top: 14px;
        left: 30px;
        height: 28px;
        width: 150px;
        object-fit: contain;
    }
    .geely {
        cursor: pointer;
        display: flex;
        align-items: center;
        color: #fff;
        line-height: 28px;
        font-size: 16px;
        top: 16px;
        img {
            width: 20px;
            height: 20px;
            margin-right: 12px;
        }
    }
}

.header .nav {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
    margin-left: 174px;
    position: relative;
}

.header .nav .nav-item {
    margin: 0 22px;
    position: relative;
    color: #ffffff;
    & > a {
        line-height: 60px;
        height: 60px;
        color: #fff;

        &:hover {
            cursor: pointer;
            color: #036fe2;
        }
    }
    .sub-div {
        white-space: nowrap;
        width: 250px;
        display: none;
        background: #0d0d0d;
        position: absolute;
        top: 60px;
        left: -30px;
        padding: 10px 0;

        &-item {
            width: 250px;
            padding: 10px 34px;
        }
    }

    &:hover {
        .sub-div {
            display: initial;
        }
    }
}

.header .nav .auto-width {
    .sub-div {
        width: auto;
        &-item {
            width: auto;
        }
    }
}

.header .nav .doc {
    .sub-div-item_link:nth-last-child(2) {
        height: 46px;
        padding-top: 15px;
        margin-top: 15px;
        border-top: 1px solid #333333;
    }
}

.header .platform {
    // width: 751px !important;
    width: 500px !important;
}
.header .platform-content {
    display: flex;
    .platform-title {
        padding: 0 36px;
        opacity: 0.5;
        font-size: 15px;
        color: #ffffff;
        line-height: 26px;
        font-weight: 500;
    }
    .online {
        width: 500px;
        // border-right: 1px solid #333333;
        &-content {
            display: flex;
        }
    }
}

.header .nav .blank {
    flex-grow: 1;
}

.header .nav-item .sub-div .sub-div-item_title {
    position: relative;
    opacity: 0.9;
    font-size: 14px;
    line-height: 18px;
    padding: 14px 10px;
    margin: 10px 0;
    border-bottom: 1px solid #333333;
    display: flex;
    align-items: center;

    &::before {
        content: '';
        position: absolute;
        width: 2px;
        height: 16px;
        left: 0;
        opacity: 0.9;
        background: #fff;
    }

    &:first-child {
        margin-top: 0;
    }
}

.header .nav-item--user-wrapper,
.header .nav-item--user-bml-wrapper,
.header .nav-item--user-wenxin-wrapper {
    display: flex;
    align-items: center;
}
.header .nav .nav-item--user-wenxin-wrapper {
    margin-right: 0;
}

.header .user-avatar {
    width: 2em;
    height: 2em;
    border-radius: 50%;
    margin-right: 4px;
}

.header .nav-item .sub-div.sub-div_user {
    width: auto;

    .sub-div-item_user {
        padding: 10px 40px;
        text-align: center;
    }
}

.header .nav-item .sub-div .sub-div-item_link {
    position: relative;
    opacity: 0.9;
    font-size: 13px;
    line-height: 30px;
    & > a {
        color: #fff;
        display: inline-flex;
        align-items: center;
        &:hover {
            cursor: pointer;
            color: #036fe2;
        }
    }
}
.header .immediately {
    display: block;
    height: 30px !important;
    line-height: 30px !important;
    padding: 0 15px;
    background-color: #1a73e8;
    cursor: pointer;

    &:hover {
        color: #fff !important;
        background-color: #1c88fc;
    }
}

.header .new {
    color: #fff;
    font-size: 12px;
    line-height: 16px;
    display: inline-block;
    height: 16px;
    width: 36px;
    margin-left: 4px;
    background-image: url(/assets/newProduct.svg);
    background-repeat: no-repeat;
    vertical-align: text-bottom;
}

.header .wenxin-tag {
    color: #fff;
    display: inline-block;
    font-size: 12px;
    line-height: 18px;
    background: #3d81ff;
    border-radius: 2px;
    padding: 1px 8px;
    margin-left: 8px;
}

.header .hot-tag {
    color: #fff;
    display: inline-block;
    font-size: 12px;
    line-height: 18px;
    background: #f33e3e;
    border-radius: 2px;
    padding: 1px 8px;
    margin-left: 8px;
}

/**
 * 个人中心改版
 */

.header {
    .nav-item .sub-div.sub-div_user_bml {
        width: 220px;
        left: auto;
        right: -22px;
        padding: 0;
        // top: 62px;
        // border-radius: 2px;

        header {
            padding: 0 16px 4px;
            .user-info {
                padding: 16px 0 14px;
                border-bottom: 1px solid #4d4d4d;
                .user-panel-avatar {
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    margin: 0 6px 0 0;
                    background: #ccc;
                }
                .user-panel-name {
                    font-size: 16px;
                    line-height: 22px;
                }
            }
        }

        .main-item {
            height: 40px;
            padding: 0 16px;
        }

        .sub-items {
            display: flex;
            padding-left: 40px;
            flex-direction: row;
            flex-wrap: wrap;
            a.sub-item {
                margin: 0;
                height: 36px;
                line-height: 36px;
                opacity: 0.7344;
                width: 50%;
                display: block;
            }
        }

        footer {
            padding: 4px 16px 6px;
            a.main-item {
                border-top: 1px solid #4d4d4d;
                height: 40px;
                line-height: unset;
                padding: 0;
            }
        }
    }

    .flex-row,
    .nav .nav-item a.flex-row {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
    }

    .user-panel-icon {
        width: 16px;
        height: 16px;
        display: block;
        margin-right: 8px;

        &[role='user-icon'] {
            background: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='.01' fill='%23000' d='M0 0h16v16H0z'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.823 5.444a3.714 3.714 0 0 1-1.646 3.087c2.22.849 3.788 2.983 3.815 5.452h-1.098c0-2.443-1.829-4.449-4.207-4.784h-.052c-.483-.045-1.046-.01-1.192-.001l-.036.001c-2.43.283-4.337 2.317-4.337 4.787H1.999c0-2.495 1.567-4.606 3.814-5.481a3.715 3.715 0 0 1-1.646-3.086c0-2.058 1.699-3.755 3.815-3.755 2.116 0 3.84 1.697 3.84 3.78zM5.108 5.42c0 1.569 1.28 2.829 2.874 2.829s2.874-1.26 2.874-2.83c0-1.568-1.28-2.828-2.874-2.828S5.108 3.85 5.108 5.419z' fill='%23fff'/%3E%3C/svg%3E");
        }

        &[role='finance-icon'] {
            background: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='M13.378 4.611c-.113 0-.206-.102-.206-.232v9c0-.13.094-.232.206-.232H2.622c.113 0 .206.102.206.232v-9c0 .13-.094.232-.206.232h10.756z' stroke='%23FEFFFF'/%3E%3Cpath d='M12.758 3c.229 0 .414-.224.414-.5s-.185-.5-.414-.5H3.242c-.229 0-.414.224-.414.5s.185.5.414.5h9.516zM10.07 10.51a.414.414 0 0 0 0-.83H5.93a.414.414 0 0 0 0 .83h4.14zM5.93 8.226a.414.414 0 0 0 0 .83h4.14a.414.414 0 0 0 0-.83H5.93z' fill='%23FEFFFF' fill-rule='nonzero'/%3E%3Cpath d='M7.586 11.34a.414.414 0 1 0 .828 0V8.848a.414.414 0 1 0-.828 0v2.492z' fill='%23FEFFFF' fill-rule='nonzero'/%3E%3Cpath d='M8.44 8.681l1.462-1.467a.416.416 0 0 0 0-.587.413.413 0 0 0-.586 0L7.854 8.094a.205.205 0 0 1 .292 0 .413.413 0 0 0-.585 0 .416.416 0 0 0 0 .587.62.62 0 0 0 .878 0z' fill='%23FEFFFF' fill-rule='nonzero'/%3E%3Cpath d='M7.56 8.681a.413.413 0 0 0 .586 0 .416.416 0 0 0 0-.587L6.684 6.627a.413.413 0 0 0-.586 0 .416.416 0 0 0 0 .587l1.463 1.467z' fill='%23FEFFFF' fill-rule='nonzero'/%3E%3C/g%3E%3C/svg%3E");
        }

        &[role='quit-icon'] {
            background: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='.01' fill='%23000' d='M0 0h16v16H0z'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.453 6.925a.552.552 0 0 0 1.103 0V2.552a.552.552 0 0 0-1.103 0v4.373zM2 8.405a6.01 6.01 0 0 0 6.004 6.003 6.011 6.011 0 0 0 6.006-6.003 5.961 5.961 0 0 0-1.76-4.246.551.551 0 1 0-.78.78 4.866 4.866 0 0 1 1.437 3.466c0 2.702-2.2 4.9-4.903 4.9a4.906 4.906 0 0 1-4.901-4.9c0-1.292.497-2.511 1.4-3.432a.552.552 0 0 0-.787-.772A5.97 5.97 0 0 0 2 8.405z' fill='%23fff'/%3E%3C/svg%3E");
        }
    }

    a:hover .user-panel-icon {
        &[role='user-icon'] {
            background: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.177 8.53a3.714 3.714 0 0 0 1.646-3.086c0-2.083-1.725-3.78-3.841-3.78-2.117 0-3.815 1.697-3.815 3.755 0 1.286.653 2.417 1.646 3.086-2.247.875-3.815 2.985-3.815 5.48H3.07c0-2.469 1.907-4.503 4.337-4.786.026 0 .68-.05 1.228 0h.052c2.378.335 4.207 2.341 4.207 4.785h1.097c-.026-2.47-1.593-4.604-3.814-5.453zm-2.195-.282c-1.594 0-2.874-1.26-2.874-2.83 0-1.568 1.28-2.828 2.874-2.828s2.874 1.26 2.874 2.829c0 1.569-1.28 2.829-2.874 2.829z' fill='%23036FE2' fill-rule='nonzero'/%3E%3C/svg%3E");
        }

        &[role='quit-icon'] {
            background: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.004 14.408A6.01 6.01 0 0 1 2 8.405a5.97 5.97 0 0 1 1.716-4.204.551.551 0 1 1 .788.772 4.874 4.874 0 0 0-1.401 3.432c0 2.702 2.199 4.9 4.901 4.9a4.907 4.907 0 0 0 4.903-4.9c0-1.31-.51-2.54-1.437-3.466a.551.551 0 1 1 .78-.78 5.961 5.961 0 0 1 1.76 4.246 6.011 6.011 0 0 1-6.006 6.003zm0-6.931a.552.552 0 0 1-.55-.552V2.551a.552.552 0 0 1 1.102 0v4.374a.552.552 0 0 1-.551.552z' fill='%23036FE2' fill-rule='nonzero'/%3E%3C/svg%3E");
        }
    }
}
