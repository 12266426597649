@keyframes light {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 0.3;
    }

    100% {
        opacity: 0;
    }
}

.solution_intro {
    display: flex;
    padding: 30px 40px;
    border: 1px solid #ebebeb;
    .left {
        width: 536px;
        vertical-align: top;
        margin-right: 12px;
        .title {
            font-size: 20px;
            line-height: 28px;
            margin-bottom: 12px;
        }

        .desc {
            font-size: 12px;
            color: #666;
            line-height: 20px;
        }
    }
    .right {
        position: relative;
        width: 508px;
        padding-left: 40px;
        vertical-align: top;
        border-left: 1px solid #f0f0f0;
        box-sizing: content-box;
        position: relative;
    }
    .chart {
        position: relative;

        .chart-axis,
        .chart-axis-line-x {
            stroke: #ebebeb;
            stroke-dasharray: 0;
            stroke-width: 1;
        }
        .text-wrapper {
            color: #000;
            font-size: 12px;

            .end {
                text-anchor: end;
            }
        }

        .icon_wrapper {
            position: absolute;
            top: 60px;
            left: 0;
            bottom: 28px;
            width: 100%;

            .prod_batch_item {
            }

            .prod_item {
                position: absolute;
                z-index: 9;
                cursor: pointer;
                font-size: 14px;
                &:hover {
                    & + .tzfont {
                        transition: all 0.2s;
                        opacity: 0.7;
                    }
                }

                .fast_light {
                    width: 33px;
                    height: 32px;
                    border-radius: 50%;
                    position: absolute;
                    background: #1a73e8;
                    opacity: 0;
                    left: -5px;
                    top: -5px;
                    z-index: -9;
                    &::before {
                        width: 18px;
                        height: 18px;
                        background: #fff;
                        content: '';
                        position: absolute;
                        top: 8px;
                        left: 8px;
                        border-radius: 50%;
                    }
                    &.active {
                        animation: light 0.8s linear infinite;
                    }
                }

                .tzfont {
                    font-size: 22px;
                    color: #1a73e8;
                    &_5,
                    &_3,
                    &_4,
                    &_6,
                    &_7 {
                        color: #00cb87;
                        & + .popover {
                            color: #00cc88;
                        }
                    }
                }
                &.active {
                    .popover {
                        background: #1a73e8;
                        color: #fff;
                    }
                }

                &_6,
                &_3,
                &_4,
                &_7 {
                    &.active .popover {
                        background: #00cb87;
                        color: #fff;
                    }
                }
                .popover {
                    position: absolute;
                    white-space: nowrap;
                    padding: 4px 18px;
                    color: #1a73e8;
                    line-height: 22px;
                    background: #fff;
                    box-shadow: 0 4px 22px 0 rgba(0, 0, 0, 0.08);
                    transition: all 0.2s;
                    &:hover {
                        span {
                            opacity: 0.7;
                        }
                    }

                    &::before {
                        display: inline-block;
                        content: '';
                        position: absolute;
                        width: 6px;
                        height: 6px;
                        background: inherit;
                        margin-top: -3px;
                        box-shadow: 0 2px 22px 0 rgba(0, 0, 0, 1);
                        transform: rotate(45deg);
                        z-index: -1;
                    }
                    &_right {
                        left: 30px;
                        top: -2px;
                        &::before {
                            left: -3px;
                            top: 50%;
                        }
                    }
                    &_left {
                        top: -2px;
                        right: 30px;
                        &::before {
                            right: -3px;
                            top: 50%;
                        }
                    }
                    &_top {
                        top: -38px;
                        transform: translateX(calc(-50% + 11px));
                        &::before {
                            bottom: -3px;
                            left: 50%;
                            margin-left: -3px;
                            transform: rotate(45deg);
                        }
                    }
                    &_bottom {
                        top: 30px;
                        transform: translateX(calc(-50% + 11px));
                        &::before {
                            top: 0;
                            left: 50%;
                            margin-left: -3px;
                            transform: rotate(45deg);
                        }
                    }
                }
                &:nth-child(1) {
                    top: 200px;
                    left: 312px;
                }

                // &:nth-child(2) {
                //     top: 152px;
                //     left: 336px;
                // }

                &:nth-child(2) {
                    top: 292px;
                    left: 208px;
                }

                &:nth-child(3) {
                    top: 384px;
                    left: 54px;
                }

                &:nth-child(4) {
                    top: 54px;
                    left: 380px;
                }

                &:nth-child(5) {
                    top: 207px;
                    left: 263px;
                }

                &:nth-child(6) {
                    top: 54px;
                    left: 432px;
                }

                &:nth-child(7) {
                    top: 336px;
                    left: 158px;
                }

                &:nth-child(8) {
                    top: 336px;
                    left: 120px;
                }
            }
            .prod_batch {
                position: absolute;
                top: -62px;
                .popover {
                    display: none;
                }
                &:hover {
                    .popover {
                        display: block;
                    }
                }
                .popover_ceil {
                    background: #e7fef6 !important;
                    color: #00cb87 !important;
                }
                &::after {
                    width: 0;
                    height: 42px;
                    position: absolute;
                    left: 10px;
                    top: 22px;
                    content: '';
                    border-left: 1px dashed rgba(0, 203, 135, 0.5);
                }
                .tzfont {
                    color: #7fe5c3;
                }
            }
        }
    }
}
.right {
    .solution_intro_select {
        display: none;
        position: absolute;
        background: #fff;
        top: 48px;
        left: 40px;
        height: 212px;
        overflow-y: auto;
        padding: 20px;
        z-index: 1;
        border: 1px solid #e1e1e1;

        &_item {
            font-size: 20px;
            line-height: 28px;
            margin-bottom: 20px;
            cursor: pointer;
            color: #333;

            &:hover {
                color: #036fe2 !important;
            }

            &.active {
                color: #000;
                font-weight: 500;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .solution_intro_title {
        line-height: 28px;
        font-size: 20px;
        margin-bottom: 20px;
        cursor: pointer;
        font-weight: 500;

        .tzfont {
            font-size: 14px;
            color: #979797;
            margin-left: 10px;
            transition: all 0.2s;
        }

        &.active + .solution_intro_select {
            display: block;
        }

        &.active {
            .tzfont {
                transform: rotate(180deg);
            }
        }
    }

    .solution_attrs {
        display: table;
        width: 380px;
        height: 143px;
        border: 1px solid #ebebeb;
        .img_wrapper {
            display: table-cell;
            vertical-align: middle;
            & > img {
                height: 120px;
            }
        }
        .attr_wrapper {
            display: table-cell;
            vertical-align: middle;
        }
        .attr_item {
            font-size: 12px;
            line-height: 20px;
            color: #666;
            margin-bottom: 8px;
            position: relative;
            white-space: nowrap;
            padding: 0 25px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .sub_title {
        font-size: 16px;
        line-height: 24px;
        margin: 20px 0 12px;
        font-weight: 500;
    }
    // .solution_intro_desc {
    //     color: #333;
    //     line-height: 24px;
    //     font-size: 16px;
    //     margin-bottom: 10px;
    // }
    .solution_intro_attrs {
        padding-left: 14px;
        font-size: 14px;
        &_item {
            line-height: 22px;
            margin-bottom: 10px;
            position: relative;

            &::before {
                content: '';
                position: absolute;
                left: -18px;
                top: 8px;
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background: #1a73e8;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .solution_intro_param {
        height: 200px;
    }
    .ops {
        margin-top: 30px;
        .link {
            display: inline-block;
            width: 130px;
            height: 38px;
            line-height: 38px;
            border: 1px solid #1a73e8;
            text-align: center;
            font-size: 16px;

            &-single {
                color: #1a73e8 !important;
                background: #fff !important;

                &:hover {
                    color: #1c88fc !important;
                }
            }

            &:first-child {
                color: #fff;
                background: #1a73e8;
                margin-right: 20px;

                &:hover {
                    background: #1c88fc;
                }
            }

            &:last-child {
                color: #1a73e8;
            }
        }
    }
    .thead {
        border: 1px solid #eee;
        border-bottom: 0;
        font-size: 14px;
        &.placeholder {
            .thead_wrapper {
                padding-left: 104px;
            }
        }

        span {
            display: inline-block;
            width: 50%;
            text-align: center;
            height: 40px;
            line-height: 40px;
        }
    }

    .param_table {
        width: 100%;
        border: 1px solid #eee;
        font-size: 14px;
        table-layout: fixed;
        border-collapse: collapse;

        tbody {
            tr {
                border-top: 1px solid #eee;
            }
        }

        td {
            height: 60px;
            text-align: center;
            // border-right: 1px solid #eee;
            white-space: nowrap;
            height: 40px;

            &:last-child {
                border-right: 0;
            }
        }
    }
}

.jetson {
    &_intro {
        margin-bottom: 60px;

        .left {
            display: inline-block;
            vertical-align: top;

            .img {
                width: 216px;
            }
        }

        .right {
            display: inline-block;
            width: 894px;
            vertical-align: top;
            padding-left: 30px;

            .text_wrapper {
                font-size: 16px;
                color: #666;
                line-height: 26px;
            }

            .links {
                margin-top: 12px;
            }

            .link {
                margin-right: 30px;
                font-size: 16px;
                color: #1a73e8;
                line-height: 26px;

                &:hover {
                    border-bottom: 1px solid;
                }
            }
        }
    }

    &_content {
        .jetson_item {
            border: 1px solid #ebebeb;
            padding: 0 0 0 40px;
            margin-bottom: 30px;
            display: block;

            &:last-child {
                margin-bottom: 0;
            }

            &[href]:hover {
                box-shadow: 0 10px 10px 10px rgba(0, 0, 0, 0.05);
            }
        }

        .left,
        .right {
            display: inline-block;
            vertical-align: top;
        }

        .left {
            width: 728px;
            padding-right: 60px;
            box-sizing: content-box;

            .title {
                font-size: 30px;
                line-height: 46px;
                padding-top: 30px;
            }

            .desc {
                line-height: 22px;
                margin: 12px 0;
                font-size: 14px;
            }

            .param {
                line-height: 22px;
                font-size: 14px;
            }
        }

        .right {
            width: 350px;
        }
    }
}
