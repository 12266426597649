.video {
    width: 768px;
    margin: 0 auto;
}

:global(.modal_wrap).wrapper {
    .modal {
        padding: 0;
        background: transparent;
    }

    .modal-close > i {
        font-size: 16px;
        color: #fff;
        -webkit-text-stroke: 1px #e1e1e1;
        cursor: pointer;
    }
}

:global(.wenxin):global(.modal_wrap).wrapper {
    // 不覆盖header
    z-index: 999;
    background: rgba(0, 0, 0, 0.9);
    .video {
        width: auto;
        max-width: calc(100vw - 160px);
        max-height: calc(100vh - 160px);
        margin: 0 80px;
    }
    .modal-close {
        top: 0;
        right: 32px;
        & > img {
            height: 32px;
            &:hover {
                opacity: 0.4;
            }
        }
    }
}

:global(.mobile):global(.modal_wrap).wrapper {
    .video {
        width: 100vw;
        aspect-ratio: 16 / 9;
    }
    .modal-close {
        top: 234px;
        right: 48px;
        & > img {
            height: 98px;
        }
    }
}

.disable-scroll {
    height: 100vh;
    overflow: hidden;
}
