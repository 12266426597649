.usageProcess {
    height: 600px;
    font-size: 20px;
    display: flex;
    align-items: flex-start;
    .usageProcess-navs {
        width: 340px;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.05);
        margin-top: 64px;
        margin-right: 30px;
    }
    .usageProcess-nav {
        background: #ffffff;
        font-size: 20px;
        line-height: 22px;
        padding: 29px 30px 9px 50px;
        // transition: all .3s ease-out;
        height: 80px;
        position: relative;
        counter-increment: count 1;
        &::before {
            content: counter(count);
            opacity: 0.15;
            font-size: 40px;
            color: #a6a6a6;
            line-height: 80px;
            font-family: DINAlternate-Bold;
            position: absolute;
            left: 15px;
            top: 0;
        }
        &_title {
            padding-bottom: 20px;
            font-size: 20px;
            line-height: 22px;
            color: #000;
            // transition: all .3s ease-out;
            & > span {
                margin-right: auto;
            }
            & > i {
                font-size: 12px;
                margin-left: auto;
                float: right;
                color: #999;
            }
        }
        &_desc {
            opacity: 0.8;
            font-size: 14px;
            color: #ffffff;
            text-align: justify;
            line-height: 26px;
            height: 0;
            overflow: hidden;
            // transition: all .3s ease-out;
            padding-bottom: 10px;
        }
        &_activity {
            height: auto;
            padding-top: 20px;
            background-image: linear-gradient(135deg, #2fa7ff 0%, #1a73e8 100%);
            .usageProcess-nav_title {
                color: #fff;
                & > i {
                    color: #fff;
                    transform: rotate(180deg);
                }
            }
            .usageProcess-nav_desc {
                height: auto;
            }
        }
    }
    .usageProcess-banner {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 510px;
        width: 830px;
        // width: 900px;
        .usageProcess-banner_item_wrap {
            overflow: hidden;
            height: 510px;
            width: 0;
        }
        .usageProcess-banner_item_container {
            height: 510px;
            width: 830px;
            // width: 900px;
        }
        .activity {
            height: 100%;
            width: 100%;
        }
        &_item {
            background: #f8f8f8;
            object-fit: contain;
            width: 830px;
            height: 500px;
        }
    }
    .usageProcess-swiper-pagination {
        position: absolute;
        z-index: 20;
        text-align: center;
        height: 10px;
        .usageProcess-pagination-bullet {
            cursor: pointer;
            background: #d8d8d8;
            width: 40px;
            height: 5px;
            margin-right: 8px;
            display: inline-block;
            &-active {
                background: #1a73e8;
            }
        }
    }
}
