.partner {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    &-item {
        position: relative;
        height: 96px;
        width: 224px;
        overflow: hidden;
        border: 1px solid #e1e1e1;
        box-shadow: 0 2px 6px 0 rgba(25, 28, 61, 0.08);
        margin-bottom: 30px;
        &:hover {
            box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
            // .partner-container {
            //     transform: translateY(-94px);
            // }
            .partner-hidden{
                opacity: 1;
            }
        }
        &:nth-last-of-type(1),
        &:nth-last-of-type(2),
        &:nth-last-of-type(3),
        &:nth-last-of-type(4),
        &:nth-last-of-type(5) {
            margin-bottom: 0;
        }
    }
    &-logo {
        height: 100%;
        width: 100%;
        object-fit: cover;
        display: block;
    }
    &-hidden {
        display: block;
        transition: all ease-in-out 0.2s;
        background: #fff;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        height: 100%;
        width: 100%;
        padding: 20px 24px;
        border-bottom: 2px solid #036fe2;
    }
    &-name {
        font-size: 14px;
        color: #036fe2;
        line-height: 22px;
        margin-bottom: 8px;
        display: flex;
        align-items: center;
        font-weight: 500;
        & > i {
            font-size: 12px;
            margin-left: 5px;
        }
    }
    &-desc {
        font-size: 14px;
        color: #666666;
        line-height: 20px;
    }
}
