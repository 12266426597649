@import 'swiper/swiper.less';
@import 'swiper/components/pagination/pagination.less';
@import 'swiper/components/effect-fade/effect-fade.scss';
@import '@baidu/tianzhi-icons';

html,
body {
    height: 100%;
    width: 100%;
    min-width: 1200px;
    padding: 0;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

a {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

img {
    width: 100%;
}
ol,
ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.modal_section {
    .section-title_container {
        padding-bottom: 30px;
    }
}

.section {
    margin: 0 auto;
    font-size: 0;
    &-wrap {
        padding-top: 60px;
        padding-bottom: 60px;
    }
    &-ghostWhite {
        background: #f8f8f8;
    }
    &-white {
        background: transparent;
    }
    &-title_container {
        padding-bottom: 60px;
    }
    &-title {
        width: 100%;
        font-size: 32px;
        text-align: center;
        font-weight: 400;
        letter-spacing: initial;
    }
    &-desc {
        padding-top: 20px;
        font-size: 16px;
        color: #666666;
        text-align: center;
        a {
            color: #0073eb;
        }
    }
    .subSection-title_container {
        padding-bottom: 80px;
    }
    .subSection-desc {
        padding-top: 30px;
    }
}

.step-desc {
    a:hover {
        text-decoration: underline;
    }
}
.common-container {
    padding-top: 60px;
    .section {
        width: 1180px;
    }
    .subPage-firstSession {
        padding-top: 120px;
    }
}
.getHF-container {
    .section {
        width: 1180px;
    }
}

.bml-container {
    padding-top: 60px;
    .section {
        width: 1200px;
    }
}

@animate-duration: 1s;
@animate-delay: 0.2s;
@animate-repeat: 1;

.generate-animate__delay(10);

.generate-animate__delay(@n, @i: 1) when (@i =< @n) {
    .animate__delay-@{i} {
        animation-delay: calc(@animate-delay * @i);
    }
    .generate-animate__delay(@n, (@i + 1));
}

.modal_wrap {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: 1002;
    display: flex;
    justify-content: center;
    align-items: center;
    .modal {
        position: relative;
        background: #fff;
        // width: 540px;
        padding: 4px 20px 0 20px;
        &-title {
            font-size: 14px;
            text-align: center;
            line-height: 22px;
            text-align: center;
            padding: 16px 0;
            border-bottom: 1px solid #eee;
        }
        &-close {
            position: absolute;
            top: 25px;
            right: 20px;
            cursor: pointer;
            z-index: 100;
            & > i {
                font-size: 12px;
                color: #999;
            }
        }
        &-container {
            padding: 20px 0;
        }
    }
}
