.paddle_sidebar {
    color: #151b26;
    height: calc(100vh - 60px);
    width: 180px;
    min-width: 180px;
    min-height: 360px;
    text-align: left;
    transition: width, left, right, 0.3s;
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: 999;
    background: #ffffff;
    box-shadow: 0 2px 8px 0 rgba(7, 12, 20, 0.12);
    font-family: PingFangSC-Regular;
    font-size: 14px;

    &__header {
        height: 48px;
        padding: 13px 16px;

        div {
            height: 100%;
            overflow: hidden;
        }

        span {
            &:first-child {
                margin: 0px auto;
            }

            &:last-child {
                display: inline-block;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                color: #84868c;
            }
        }
    }

    &__content {
        flex-grow: 1;
        overflow-y: auto;
        .sidebar__content {
            nav {
                padding-top: 8px;
            }

            &::before {
                content: '';
                display: block;
                margin: 0 16px;
                height: 1px;
                background-color: #e8e9eb;
            }

            &_item {
                position: relative;
                &_hover {
                    &:hover {
                        img{
                            position: relative;
                            left: -80px;
                            filter: drop-shadow(#2568f3 80px 0);
                        }
                    }
                }
                &_action {
                    .item_text_action {
                        background-color: #EEF3FE;
                        color: #2468F2;
                        &::before {
                            background-color: #2468F2;
                        }
                    }
                    .sidebar__item_content {
                        color: #2468F2;
                    }
                }

                .sidebar__inner_item {
                    position: relative;
                    display: flex;
                    align-items: center;
                    padding: 13px 16px 13px 17px;
                    cursor: pointer;
                    transition: padding-left 0.3s;

                    .sidebar__icon_wrapper {
                        margin-right: 9px;
                        width: 14px;
                        display: flex;
                        height: 100%;
                        align-items: center;
                        justify-content: center;
                        img {
                            transform: translateX(2px);
                        }
                    }

                    .sidebar__item_content {
                        flex: 1;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }

                    .sidebar__arrow_wrapper {
                        .sidebar__arrow {
                            width: 16px;
                            height: 16px;
                        }
                    }
                }
                .item_action {
                    background-color: #eef3fe;
                    &::before {
                        background-color: #2468f2;
                    };
                }
                .sidebar__item_action[class] {
                    padding-left: 21px;

                    &::before {
                        position: absolute;
                        content: '';
                        display: inline-block;
                        width: 3px;
                        margin-right: 1px;
                        top: 0px;
                        bottom: 0px;
                        left: 0px;
                    }
                }

                .sidebar__inner_list_item {
                    overflow: hidden;
                    display: flex;
                    flex-flow: column nowrap;
                    align-items: stretch;
                    cursor: pointer;
                    transition: height, 0.3s;

                    span {
                        flex: 1;
                        padding: 9px 0px 9px 40px;
                    }

                    &_title {
                        font-family: PingFangSC-Regular;
                        font-size: 14px;
                        text-align: left;
                        color: #151B26;
                        line-height: 22px;
                        padding-bottom: 13px;
                        margin-bottom: 13px;
                        border-bottom: 1px solid #E8E9EB;
                    }

                    &_text {
                        position: relative;
                        font-size: 14px;
                        line-height: 22px;
                        white-space: nowrap;
                        &:hover {
                            color: #2468F2;
                        }

                        &::before {
                            position: absolute;
                            content: '';
                            display: inline-block;
                            width: 3px;
                            margin-right: 1px;
                            top: 0px;
                            bottom: 0px;
                            left: 0px;
                        }

                    }
                }

                &:hover .sidebar__list_item_action[class] {
                    display: flex;
                }

                .sidebar__list_item_action[class] {
                    display: none;
                    position: absolute;
                    white-space: nowrap;
                    background: rgb(255, 255, 255);
                    box-shadow: rgba(7, 12, 20, .12) 0px 2px 8px 0px;
                    border-radius: 2px;
                    height: auto !important;
                    top: 0%;
                    left: 100%;
                    transform: translateX(4px);
                    padding: 13px 20px;
                    .item_text_action {
                        background-color: #fff;

                        &::before {
                            display: none;
                        }
                    }

                    .sidebar__inner_list_item_text {
                        padding: 0px;
                        margin-bottom: 10px;
                    }

                    span {
                        padding-top: 0px;
                        padding-left: 0px;

                        &:last-child {
                            margin-bottom: 0px;
                        }
                    }
                }
            }
        }
    }

    &__footer {
        padding: 8px 16px;
        border-top: 1px solid #e8e9eb;
        color: #151b26;
        height: 37px;

        div {
            display: flex;
            align-items: center;
            height: 100%;
            overflow: hidden;
            cursor: pointer;

            span:last-child {
                flex-grow: 1;
                flex-flow: 1;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
    }

    &__icon {
        display: flex;
        margin: 0px 9px;
        height: 100%;
        align-items: center;
        justify-content: center;
        height: 100%;
        img {
            width: 16px;
        }
    }
}

.collapsed[class] {
    width: 60px;
    min-width: 60px;
    .paddle_sidebar__content {
        overflow-y: initial;
    }
}