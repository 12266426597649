.aside_container {
    position: fixed;
    width: 50px;
    right: 10px;
    top: 45%;
    z-index: 998;
}
.gravity {
    & > img {
        width: 67px;
        margin-left: -9px;
    }
    display: block;
    margin-top: -189px;
}

.aside {
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
    background-color: #fff;
    padding-top: 8px;
    color: #000;
    .aside_item {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        img {
            width: 60%;
        }
        span {
            display: block;
            margin-top: 4px;
            font-size: 12px;
        }
    }
    &-item {
        width: 100%;
        height: 58px;
        position: relative;
        cursor: pointer;


        &:hover {
            .aside-inner,
            .aside-text,
            .aside-cooperation-innner {
                display: block;
            }
        }
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &-icon {
        fill: #000;
    }

    &-text {
        display: none;
        position: absolute;
        width: 30px;
        height: 32px;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        color: #000;
        line-height: 16px;
        font-size: 13px;
        padding: 13px 10px;
        box-sizing: content-box;
        text-align: center;
        background-color: #f7f7f7;
    }

    &-inner {
        display: none;
        position: absolute;
        right: 70px;
        top: 50%;
        transform: translateY(-50%);
        padding: 10px 6px 6px;
        background-color: #fff;
        color: #000;
        text-align: center;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        border-radius: 4px;
        box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);

        &-title {
            margin-bottom: 8px;
            font-size: 14px;
        }

        &-sub {
            margin-bottom: 10px;
            font-size: 12px;
        }

        &-img {
            width: 147px;
            height: 147px;
            background: url(../../assets/qr.png) no-repeat 50%;
            background-size: 147px 147px;
        }

        &-triangle {
            position: absolute;
            right: -10px;
            top: 50%;
            transform: rotate(-45deg) translateY(-50%);
            width: 12px;
            height: 12px;
            border-color: transparent #fff #fff transparent;
            border-style: solid;
            border-width: 6px;
            background: transparent;
            box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.1);
            box-sizing: border-box;
        }
    }

    .aside-cooperation-innner {
        display: none;
        position: absolute;
        right: 50px;
        top: 50%;
        transform: translateY(-50%);
        padding-right: 20px;
    }

    &-cooperation {
        padding: 9px 0;
        width: 308px;
        background-color: #fff;
        color: #000;
        text-align: center;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        border-radius: 4px;
        box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);

        &-wrap {
            padding: 15px 30px 0;
            text-align: left;
            display: block;
            &-title {
                position: relative;
                font-size: 14px;
                color: #000;
                line-height: 22px;
                margin-bottom: 6px;
                font-weight: 700;
                padding-left: 38px;
            }

            &-desc {
                font-size: 12px;
                padding-left: 38px;
                color: #666;
                line-height: 20px;
                padding-bottom: 15px;
            }
            &-phone {
                color: #006be5;
            }
            &:hover {
                background: #f8f8f8;
            }
        }

        &-triangle {
            position: absolute;
            right: 10px;
            top: 50%;
            transform: rotate(-45deg) translateY(-50%);
            width: 12px;
            height: 12px;
            border-color: transparent #fff #fff transparent;
            border-style: solid;
            border-width: 6px;
            background: transparent;
            box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.1);
            box-sizing: border-box;
        }

        &-presale,
        &-service,
        &-phone {
            position: absolute;
            left: 0;
            top: 0;
            width: 22px;
            height: 22px;
        }
        &-presale {
            background: url(../../assets/presale.png) no-repeat;
            -moz-background-size: cover;
            background-size: cover;
        }
        &-service {
            background: url(../../assets/service.png) no-repeat;
            -moz-background-size: cover;
            background-size: cover;
        }
        &-phone {
            background: url(../../assets/phone.png) no-repeat;
            -moz-background-size: cover;
            background-size: cover;
        }
    }

    .aside-icon-consult {
        width: 24px;
        height: 18px;
    }

    .aside-icon-qm {
        width: 24px;
        height: 24px;
    }

    .aside-icon-back {
        width: 17px;
        height: 17px;
    }
    .hidden {
        display: none;
    }
}
