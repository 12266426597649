.relyon {
    font-size: 16px;
    .suite_edition {
        padding: 10px 0px;
    }
    &_title {
        font-family: PingFangSC-Medium;
        font-size: 20px;
        color: #151B26;
        line-height: 26px;
        border-bottom: 1px solid #E8E9EB;
        padding-bottom: 24px;
        margin-bottom: 24px;

        p {
            margin-top: 16px;
            font-family: PingFangSC-Regular;
            font-size: 14px;
            color: #5C5F66;
            line-height: 22px;
        }
    }

    &_table {
        font-size: 14px;
        color: #151B26;
        line-height: 22px;

        &_th {
            background-color: #F7F7F9;
            color: #5C5F66;

            th {
                font-weight: 500;
                padding: 10px 12px;
                text-align: left;
                border-right: 1px solid #fff;
                height: 40px;
                font-family: PingFangSC-Regular;
                font-size: 14px;
                color: #5C5F66;
                line-height: 22px;
            }
        }

        &_td {
            td {
                border-bottom: 1px solid #E8E9EB;
                padding: 0px 12px;
                font-family: PingFangSC-Regular;
                font-size: 14px;
                color: #151B26;
                line-height: 22px;
            }

            &_python {
                span {
                    display: block;
                    height: 42px;
                    line-height: 42px;
                    padding-left: 12px;
                    border-bottom: 1px solid #E8E9EB;

                    &:last-child {
                        border-bottom: none;
                    }
                }
            }

            &_environment {
                font-size: 12px;
                line-height: 20px;
                a {
                    color: #2468F2;
                }
            }

        }
    }
}