// 子页面 临时样式 product 中不存在子页面
.vis_banner_tip {
    color: #faad15;
    line-height: 33px;
    font-size: 14px;
    margin: 0 0 30px;
    &-tag {
        border: 1px solid #faad15;
        border-radius: 2px;
        margin-right: 10px;
        font-size: 12px;
        padding: 2px 4px;
    }
    & > a {
        color: #faad15;
        &:hover {
            border-bottom: 1px solid #faad15;
        }
    }
    &-num {
        font-size: 24px;
    }
}
