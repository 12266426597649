.temporary {
    display: flex;
    flex-flow: column nowrap;
    img {
        width: 100%;
    }
}
.gift-bag {
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    font-size: 16px;
    overflow: hidden;
    background-image: url(assets/giftBag/bg.png);
    &>img {
        width: 100%;
        height: 100%;
    }

    &-content {

        margin: 0 auto;
        width: 1200px;
    }
}

.recommend {
    margin-top: 95px;
    padding: 140px 82px 0;
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    color: #FFFFFF;


    &-trumpet {
        position: absolute;
        width: 118px;
        top: -14px;
        left: -27px;
    }
&>.recommend-title {
    background-size: cover;
}
    &-title {
        position: absolute;
        font-family: Microsoft Yahei;
        font-family: YouSheBiaoTiHei;
        top: -38px;
        left: 210px;
        font-size: 60px;
        letter-spacing: 0;
        text-align: center;
        line-height: 45px;
        font-weight: 400;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        text-align: center;

        span {
            width: max-content;
            display: inline-block;
            padding: 26px 185px;
            vertical-align: middle;
            b {
                color: #FF62A3;
            }
            &::after,
            &::before {
                vertical-align: top;
                width: 40px;
                height: 45px;
                content: '';
                display: inline-block;
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
            }

            &::after {
                margin-left: 12px;
                background-image: url(assets/giftBag/recommend-title-right.svg);
            }

            &::before {
                margin-right: 12px;
                background-image: url(assets/giftBag/recommend-title-left.svg);
            }
        }
    }

    .recommend-desc {
        font-family: NotoSansHans-Regular;
        font-size: 18px;
        letter-spacing: 0;
        line-height: 27px;
        font-weight: 400;

        span {
            color: #09E1E5;
            letter-spacing: 0;
            line-height: 28px;
            font-weight: 400;
        }
    }
}


.flowPath {
    margin-top: 63px;
    margin-bottom: 57px;
    display: flex;
    justify-content: space-between;

    &>div {
        width: 172px;
        text-align: center;
        position: relative;

        &::before {
            position: absolute;
            top: 52px;
            left: -82px;
            width: 60px;
            height: 36px;
            content: '';
            display: inline-block;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            background-image: url(assets/giftBag/path-right.svg);
        }
    }

    div:first-child {
        &::before {
            content: '';
            width: 0px;
            height: 0px;
            background-image: none;
        }
    }

    .flowPath-item {
        span {
            display: inline-block;
            padding: 10px 34px;
            font-size: 14px;
            letter-spacing: 0;
            text-align: center;
            font-weight: 400;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            background-image: url(assets/giftBag/path-bg.png);
        }
        img {
            width: 100%;
            height: 152px;
        }
    }

}

.lookBack {
    margin-top: 60px;
    text-align: center;

    &>span {
        font-size: 34px;
        display: inline-block;
        padding: 15px 126px;
        letter-spacing: 2px;
        text-align: center;
        line-height: 30px;
        font-weight: 500;
        background-image: linear-gradient(270deg, rgba(0, 194, 215, 0.00) 0%,
                rgba(0, 194, 215, 0.85) 41%,
                #00C2D7 58%, rgba(0, 194, 215, 0.91) 73%, rgba(40, 34, 228, 0.00) 100%);
    }

    &>div {
        display: flex;
        margin-top: 44px;
        flex-flow: row wrap;
        justify-content: space-between;

        .lookBack-item {
            width: 216px;

            margin-bottom: 48px;

            &-bg {
                span {
                    display: inline-block;
                    margin-top: 34px;
                    padding: 15px 31px 18px 23px;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-image: url(assets/giftBag/lookBack-bg.png);
                }
            }

            div:last-child {
                height: 115.5px;
                background: #1A30B3;
                border: 2px solid #0AB5E9;
            }

            img {
                width: 100%;
                margin-bottom: 10px;
            }

            span {
                font-size: 13px;
            }
        }
    }
}

.recommend-diff {
    div {
        background-size: cover;
    }
    background-position: inherit;
    // background-size: cover;
    padding-bottom: 136px;
    margin-bottom: 50px;
    .recommend-title {
        padding: 10px 153px;
    top: -55px;
    left: 100px;
        span {
            font-size: 50px;
            padding: 26px 20px;
            padding-left: 0px;
        }
    }

    
}
.recommend-trumpet {
    top: 0;
    left: 33px;
    transform: translate(-50%, -50%);
    img {
        height: 166px;
    }


}
.awards {
    margin-bottom: 90px;
    &>p {
        padding: 5px 159px;
        font-size: 46px;
        opacity: .4;
        letter-spacing: 0;
        line-height: 28px;
        font-weight: 700;
    }

    .awards-title {
        position: relative;

        span {
            font-family: Microsoft Yahei;
            font-family: YouSheBiaoTiHei;
            clip-path: polygon(0% 0%, 85% 0, 100% 100%, 0% 100%);
            font-size: 40px;
            line-height: 28px;
            font-weight: 400;
            position: absolute;
            top: -46px;
            left: 0px;
            padding: 9px 21px 9px 42px;
            background-image: linear-gradient(90deg, #E763C3 0%, #7A39C6 100%);

            &::before {
                position: absolute;
                width: 31px;
                height: 31px;
                left: 8px;
                content: '';
                display: inline-block;
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                background-image: url(assets/giftBag/lookBack-desc.svg);
            }
        }

        p {
            font-size: 28px;
            padding: 21px 15px;
            background-image: linear-gradient(90deg, #E763C3 0%, #7A39C6 32%, rgba(122, 57, 198, 0.00) 100%);
        }

        img {
            width: 103px;
            height: 100px;
            position: absolute;
            bottom: 14px;
            right: 0px;
        }

    }
}

.awards-direction {
    text-align: right;

    .awards-title {
        span {
            clip-path: polygon(15% 0, 100% 0, 100% 100%, 0% 100%);
            left :auto;
            right: 0px;
            padding: 9px 8px 9px 54px;
            &::before {
                left: 18px;
            }

        }

        img {
            left: 0px;
            bottom: 0px;
        }
    }
}

.awardsOne {
    padding: 90px 68px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(assets/giftBag/lookBack-one-bg.png);
    text-align: center;

    span {
        display: inline-block;
    }

    &>span {
        padding: 12px 140px 15px;
        background-image: linear-gradient(270deg, rgba(155, 70, 255, 0.00) 0%,
                #9B46FF 41%, #9B46FF 58%, #9B46FF 73%, rgba(155, 70, 255, 0.00) 100%);
        font-size: 24px;
        letter-spacing: 3px;
        line-height: 30px;
        font-weight: 500;
        margin-bottom: 43px;
    }

    .awardsOne-title {
        display: flex;
        justify-content: space-between;
        align-items: center;

        img {
            width: 212px;
        }

        p {
            margin-top: -14px;
            background: #572FD6;
            border-radius: 16px;
            padding: 16px 0;
        }

        div {
            position: relative;

            &::before {
                position: absolute;
                top: 50%;
                left: -69px;
                width: 60px;
                height: 36px;
                content: '';
                display: inline-block;
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                background-image: url(assets/giftBag/path-right-b.svg);
            }
        }

        div:first-child {
            &::before {
                content: '';
                width: 0px;
                height: 0px;
                background-image: none;
            }
        }
    }

}

.awardsTwo {
    padding: 80px 68px;
    background-image: url(assets/giftBag/lookBack-two-bg.png);
}

.awardsTwo,
.awards-card,
.awardsThree,
.awardsFour,
.qaCode,
.decorate-ball,
.decorate-giftBag,
.decorate-ball-b {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    text-align: center;
}

.awards-card {
    margin: 0 auto;
    width: 537px;
    height: 137px;
    padding: 45px 32px;
    display: flex;
    text-align: left;
    align-items: center;
    background-image: url(assets/giftBag/lookBack-two-discount.png);

    &>p {
        font-size: 54px;
        height: 75px;
        padding-right: 29px;
        margin-right: 29px;
        border-right: 2px dashed rgba(255, 255, 255, 0.55);

        span {
            font-size: 40px;
        }
    }

    &>div {
        font-size: 23px;
        p {
            white-space: nowrap;
        }
    }
}

.awards-desc {
    margin: 24px 0;
    font-size: 18px;
    opacity: 0.85;
}

.awards-text {
    transform: translateY(25px);
    opacity: 0.65;
    font-size: 15px;
}
.awardsCardImg {
    display: inline-block;
    height: 137px;
    img {
        height: 100%;
    }
}

.awardsThree {
    &>div {
        display: flex;
        justify-content: space-around;
    }
    
    //lookBack-three-bg
    padding-top: 63px;
    padding-bottom: 70px;
    background-image: url(assets/giftBag/lookBack-three-bg.png);
   .awards-card{
        width: 472px;
        background-image: url(assets/giftBag/lookBack-three-discount.png);
    }
}
.awardsFour {
    &>div {
        display: flex;
        justify-content: space-around;
    }
    
    //lookBack-three-bg
    padding-top: 63px;
    padding-bottom: 90px;
    background-image: url(assets/giftBag/lookBack-four-bg.png);
   .awards-card{
        width: 472px;
        background-size: contain;
        background-image: url(assets/giftBag/lookBack-four-discount.png);
    }
}
.qaCode {
    padding-top: 31px;
    padding-bottom: 31px;
    display: flex;
    justify-content: center;
    background-image: url(assets/giftBag/qaCode-bg.png);
    img {
        width: 201px;
    }
    div {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
    }
    p {
        margin-left: 26px;
        text-align: left;
        font-size: 26px;
    }
    .qaCode-desc {
        color: #F300B9;
    }
}
.footer {
    border-top: 1px solid #FFFFFF;
    text-align: center;
    color: #fff;
    padding-bottom: 20px;
    padding-top: 28px;
    font-size: 15px;
    opacity: 0.25;
}
.decorate-ball {
    position: absolute;
    left: -45px;
    top: 573px;
    width: 151px;
    height: 228px;
    background-image: url(assets/giftBag/decorate-ball.png);
}
.decorate-giftBag {
    position: absolute;
    top: 1345px;
    right: 0px;
    width: 163px;
    height: 246px;
    background-image: url(assets/giftBag/decorate-giftBag.png);
}
.decorate-ball-b {
    position: absolute;
    right: -21px;
    bottom: 1789px;
    width: 125px;
    height: 228px;
    background-image: url(assets/giftBag/decorate-ball-b.png);
}