.paddle_content {
    position: relative;
    display: inline-block;
    width: 100%;
    height: calc(100vh - 60px);
    min-height: 360px;
    
    &_title {
        padding: 12px 16px;
        background-color: #fff;
        line-height: 24px;
        font-family: PingFangSC-Medium;
        font-size: 16px;
        color: #151B26;

    }
    &_box {
        height: calc(100% - 56px);
        overflow-y: auto;
        background: #F7F7F9;
        &_content {
            margin: 16px 15px;
            padding: 24px;
            background: #FFFFFF;
            border-radius: 6px;
            min-height: calc(100% - 32px);
            
        }
    }
}