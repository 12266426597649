.cooperation-content {
    width: 74.375%;
    margin: 0 auto;
    padding: 80px 0 0;

    .form-body {
        color: #000;

        .form-title {
            font-size: 22px;
            margin-bottom: 40px;
        }

        .form-desc {
            position: relative;
            padding-left: 14px;
            font-size: 16px;
            margin-bottom: 25px;
            margin-top: 25px;

            &::before {
                content: '';
                position: absolute;
                left: 0;
                top: 4px;
                width: 4px;
                height: 14px;
                background: #ccc;
            }

            .tips {
                font-size: 14px;
                color: #999;
            }
        }
    }
    .form-checkbox-desc {
        color: #999;
        line-height: 20px;
    }
    .form-checkbox-input {
        width: 340px;
        margin-left: 10px;
    }
}

.model-content {
    width: 1180px;
    margin: 0 auto;
    padding-top: 50px;
}
.model-content .model-consult {
    width: 350px;
    margin: 100px auto;
    font-size: 16px;
    text-align: center;

    .model-icon-success {
        width: 75px;
        height: 75px;
        margin: 0 auto;
        background: url(../../assets/success.png) no-repeat;
        background-size: contain;
    }

    .model-icon-fail {
        width: 120px;
        height: 106px;
        margin: 0 auto;
        background: url(../../assets/fail.png) no-repeat;
        background-size: contain;
    }

    .model-title {
        margin-top: 30px;
        font-size: 26px;
    }

    .model-desc {
        margin-top: 20px;
        color: #666;
    }

    .model-back-success {
        width: 194px;
        height: 40px;
        margin: 30px auto 0;
        line-height: 40px;
        color: #999;
        background: #f0f0f0;
    }

    .model-back-fail {
        width: 136px;
        height: 40px;
        margin: 30px auto 0;
        line-height: 40px;
        color: #fff;
        background: #036fe2;
        cursor: pointer;
    }
}
