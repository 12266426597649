.bml-footer {
    vertical-align: top;
    color: #fff;
    box-sizing: border-box;
    background-color: #0c0c0c;
    min-width: 1200px;
    font-size: 16px;
    &-cover {
        width: 1200px;
        margin: 0 auto;
        padding: 60px 0;
    }

    &-content {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-around;
    }

    &-column {
        margin-right: 100px;
        white-space: nowrap;

        &:last-child {
            margin-right: 0;
        }
        &_title {
            font-size: 18px;
            opacity: 1;
            margin-bottom: 25px;
        }

        &_link {
            opacity: 0.6;
            font-size: 14px;
            color: #fff;
            line-height: 36px;
            cursor: pointer;

            &:hover {
                opacity: 1;
                color: #036fe2;
            }
        }
        &_qq {
            opacity: 0.6;
            font-size: 14px;
            color: #fff;
            line-height: 36px;
            cursor: pointer;
            &:hover {
                opacity: 1;
            }
        }
    }
}

.easydl-footer {
    font-size: 16px;
    vertical-align: top;
    font-size: 16px;
    color: #fff;
    box-sizing: border-box;
    background-color: #0c0c0c;
    min-width: 1200px;
    &-cover {
        width: 1200px;
        margin: 0 auto;
        padding: 60px 0;
    }

    &-content {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
    }
    &-about {
        width: 308px;
        text-align: left;
        font-size: 18px;
        color: #fff;
        &_title {
            margin-bottom: 20px;
        }
        &_desc {
            font-size: 14px;
            line-height: 20px;
            opacity: 0.66;
            max-width: 306px;
        }
        .learn-more {
            display: inline-block;
            margin-top: 44px;
            font-size: 14px;
            &:hover {
                .text {
                    color: #036fe2;
                }
            }
        }
        &_text {
            margin-right: 20px;
            position: relative;
            vertical-align: middle;
            color: #fff;
            cursor: pointer;
            &::before {
                content: '';
                position: absolute;
                width: 1px;
                height: 12px;
                background: #b0b0b0;
                right: -10px;
                top: 4px;
            }
        }
        &_pp {
            display: inline-block;
            width: 63px;
            height: 20px;
            vertical-align: middle;
            background-image: url(../../../assets/icons/pp.png);
            background-repeat: no-repeat;
            background-size: 100%;
        }
    }

    &-column {
        white-space: nowrap;

        &:last-child {
            margin-right: 0;
        }
        &_title {
            font-size: 18px;
            opacity: 1;
            margin-bottom: 25px;
        }

        &_link {
            opacity: 0.6;
            font-size: 14px;
            color: #fff;
            line-height: 36px;
            cursor: pointer;

            &:hover {
                opacity: 1;
                color: #036fe2;
            }
        }
        &_qq {
            opacity: 0.6;
            font-size: 14px;
            color: #fff;
            line-height: 36px;
            cursor: pointer;
            &:hover {
                opacity: 1;
            }
        }
    }
}

.gravity-footer {
    font-size: 16px;
    vertical-align: top;
    color: #fff;
    box-sizing: border-box;
    background-image: linear-gradient(180deg, #170A5B 0%, #110450 100%);
    min-width: 1100px;
    // opacity: 0.8;
    &-cover {
        width: 1100px;
        margin: 0 auto;
        padding: 60px 0;
    }

    &-content {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
    }
    &-about {
        width: 305px;
        text-align: left;
        font-size: 18px;
        color: #fff;
        &_title {
            margin-bottom: 24px;
        }
        &_desc {
            font-size: 14px;
            line-height: 24px;
            opacity: 0.7;
            max-width: 302px;
        }
        &_text {
            display: inline-block;
            margin-top: 24px;
            margin-right: 20px;
            font-size: 14px;
            position: relative;
            vertical-align: middle;
            color: #fff;
            cursor: pointer;
            &::before {
                content: '';
                position: absolute;
                width: 1px;
                height: 12px;
                background: #b0b0b0;
                right: -10px;
                top: 4px;
            }
            &:last-child {
                &::before {
                    content: none;
                }
            }
        }
    }

    &-column {
        white-space: nowrap;

        &:last-child {
            margin-right: 0;
        }
        &_title {
            font-size: 18px;
            opacity: 1;
            margin-bottom: 24px;
        }

        &_link {
            opacity: 0.7;
            font-size: 14px;
            color: #fff;
            line-height: 24px;
            cursor: pointer;
            display: inline-block;
            margin-bottom: 10px;

            &:hover {
                opacity: 1;
                color: #036fe2;
            }
        }
        &_qq {
            opacity: 0.6;
            font-size: 14px;
            color: #fff;
            line-height: 36px;
            cursor: pointer;
            &:hover {
                opacity: 1;
            }
        }
    }
}
.ai-footer_qrcode, .gravity-footer_qrcode, .ai-footer_qrcode_scene {
    padding-top: 216px;
    color: #b1b1b1;
    font-size: 14px;
    width: 200px;
    text-align: center;
    background-size: 200px 200px;
    background-repeat: no-repeat;
    & > p {
        opacity: 0.66;
    }
}
.ai-footer_qrcode {
    background-image: url(../../../assets/qr-code.jpg); 
}
.ai-footer_qrcode_scene {
    background-image: url(../../../assets/qr-code.png); 
}
.gravity-footer_qrcode{
    background-image: url(../../../assets/gravity/gravity-QR.jpg);
}

.ai-footer-line {
    font-weight: 500;
    // opacity: 0.9;
    .baseline-cover {
        width: 1200px;
        margin: 0 auto;
        padding: 18px 0;
        display: flex;
        justify-content: flex-start;
        line-height: 14px;
    }
    .baseline-item {
        font-size: 14px;
        padding: 0 33px;
        color: #fff;
        &:hover {
            color: #036fe2;
            // opacity: 1;
        }
        &:first-child{
            padding-left: 0;
        }
        &:last-child {
            margin-left: auto;
            padding: 0;
        }
    }

    .baseline_margin {
        font-size: 14px;
        opacity: 0.5;
    }
}

.default {
    background-color: #000;
}

.gravity {
    background: rgb(16, 20, 60);
    .baseline-cover {
        width: 1100px;
    }
}
