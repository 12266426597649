.highLightNum {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    &-item {
        position: relative;
        display: block;
        box-sizing: border-box;
        padding: 40px 10px 40px 20px;
        border-top: 1px solid #e1e1e1;
        width: 550px;
        display: flex;
        &:nth-last-of-type(1),
        &:nth-last-of-type(2) {
            border-bottom: 1px solid #e1e1e1;
        }

        &:hover {
            .highLightNum-desc_item {
                color: #000;
            }
        }
    }

    &-hover {
        position: relative;
        display: block;
        box-sizing: border-box;
        padding: 40px 10px 40px 20px;
        border-top: 1px solid #e1e1e1;
        width: 550px;
        display: flex;
        &:nth-last-of-type(1),
        &:nth-last-of-type(2) {
            border-bottom: 1px solid #e1e1e1;
        }
        &:hover {
            box-shadow: 4px 4px 16px 0 rgba(0, 0, 0, 0.1);
            // border变化
            border-top-color: transparent;
            border-bottom: 2px solid #036fe2;
            padding-bottom: 38px;
            .highLightNum-title > button {
                display: inline-block;
                outline: none;
            }
            &:nth-last-of-type(1),
            &:nth-last-of-type(2) {
                padding-bottom: 39px;
            }
            // 宽度变化
            width: 570px;
            padding-left: 40px;
            &:nth-of-type(odd) {
                transform: translateX(-20px);
            }
        }
    }
    &-title {
        min-width: 186px;
        flex-shrink: 0;
        & > h3 {
            font-size: 26px;
            letter-spacing: 2px;
            line-height: 40px;
            margin-bottom: 20px;
        }
        & > button {
            font-size: 14px;
            color: #036fe2;
            line-height: 22px;
            display: none;
            background-color: transparent;
            border: 0;
        }
    }
    &-subtitle {
        font-size: 18px;
        line-height: 28px;
        margin-bottom: 24px;
        margin-left: 19px;
        & > span > b {
            font-size: 24px;
            color: #1749f6;
            line-height: 28px;
        }
        & > i {
            font-size: 12px;
            padding-left: 8px;
        }
    }
    &-desc_item {
        font-size: 14px;
        color: #666666;
        line-height: 30px;
        // margin-bottom: 8px;
        padding-left: 18px;
        position: relative;
        // display: flex;
        // align-items: center;
        &::before {
            position: absolute;
            left: 0;
            top: 13px;
            content: '';
            display: inline-block;
            width: 4px;
            height: 4px;
            background: #ddd;
            margin-right: 15px;
        }
    }
    &-more {
        position: absolute;
        top: 0;
        right: 0;
        padding: 5px 15px 5px 12px;
        font-size: 12px;
        color: #fff;
        background-color: var(--swiper-theme-color);
        border-radius: 0 0 0 15px;

        &::after {
            content: '';
            display: inline-block;
            width: 5px;
            height: 5px;
            border-top: 1px solid currentColor;
            border-right: 1px solid currentColor;
            margin-left: 5px;
            transform: rotate(45deg);
            vertical-align: middle;
        }
    }
}
