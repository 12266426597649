.banner {
    position: relative;
    width: 100%;
    height: 450px;
    color: #fff;
    background-color: #666;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    font-size: 16px;
    .banner-content {
        width: 1200px;
        margin: 0 auto;
        position: absolute;
        left: 50%;
        margin-left: -600px;
        top: 50%;
        transform: translateY(-50%);
    }
    .banner-button {
        &>div {
            display: inline-block;
        }
    }
    .banner_title {
        font-size: 48px;
        line-height: 60px;
        letter-spacing: 2px;
        margin-bottom: 20px;
        margin-top: 0;
        font-weight: normal;
    }
    .banner_video {
        font-size: 16px;
        line-height: 26px;
        margin-bottom: 20px;
        margin-top: 0;
        cursor: pointer;
        &:hover {
            text-decoration: underline;
        }
    }
    .banner_detail {
        width: 100%;
        font-size: 16px;
        line-height: 26px;
        height: 84px;
    }
    &_audio,
    &_vis,
    &_nlp {
        .banner_detail {
            width: 720px;
        }
    }

    &_bml,
    &_easyedge {
        text-align: center;
    }
    &_bml {
        display: contents;
    }
}
