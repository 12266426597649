.top-page-nav {
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.05);
    background: #fff;
    text-align: center;
    white-space: nowrap;

    &.top-page-nav-fixed {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        z-index: 1001;
    }

    .top-page-nav-container {
        display: inline-block;
        font-size: 0;
        position: relative;

        .top-page-nav-bar {
            position: absolute;
            bottom: 0;
            left: 0;
            height: 3px;
            width: 130px;
            transition: all 0.2s ease-in-out;
            background: #036fe2;
        }

        .top-page-nav-item {
            display: inline-block;
            width: 130px;
            height: 70px;
            line-height: 70px;
            margin-right: 20px;
            font-size: 18px;
            color: #444;
            cursor: pointer;

            &.active {
                color: #036fe2;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }
}
