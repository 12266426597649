.practice {
    width: 100%;
    &_card {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        &_item {
            display: flex;
            width: 100%;
        }
        &_cardbox {
            width: 243px;
            background: rgba(255, 255, 255, 0.05);
            border: 1px solid #e8e9eb;
            border-radius: 6px;
            margin-bottom: 17px;
            margin-right: 18px;
            overflow: hidden;
            &:hover {
                box-shadow: 0 2px 8px 0 rgba(7,12,20,0.12);
                .cardbox_text {
                    display: inline-block;
                }
            }
            img {
                width: 100%;
                height: 152px;
            }
            &_content {
                font-family: PingFangSC-Regular;
                font-size: 14px;
                color: #151b26;
                line-height: 22px;
                padding: 12px;
                &_text {
                    position: relative;
                    &>span {
                        display: block;
                        cursor: pointer;
                        &:first-child {
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                        }   
                    }
                    .cardbox_text {
                        position: absolute;
                        display: none;
                        top: 0px;
                        left: 0px;
                        padding: 8px;
                        background-color: #ffffff;
                        box-shadow: 0 2px 8px 0 rgba(7,12,20,0.12);
                        border-radius: 4px;
                        transform: translate(0%, -112%);
                        &::after {
                            content: '';
                            position: absolute;
                            width: 12px;
                            height: 12px;
                            bottom: 0px;
                            left: 50%;
                            transform: translate(50%,50%) rotateZ(45deg);
                            background-color: #ffffff;
                        }
                    }
                    .practice_cardbox_p {
                        font-size: 12px;
                        color: #8a8c92;
                        margin-top: 8px;
                        line-height: 20px;
                        span {
                            display: block;
                            &:first-child {
                                margin-right: 8px;
                            }
                        }
                    }
                }
            }
            &:last-child {
                margin-right: 0px !important;
            }
        }
    }
}
.practice_pagination {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    &_ul {
        display: flex;
        justify-content: center;
        flex-flow: row nowrap;
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: #333333;
        letter-spacing: 0;
        text-align: center;
        line-height: 30px;
        font-weight: 400;
        li {
            width: 30px;
            height: 30px;
            border-radius: 4px;
            color: #151b26;
            &:hover {
                .s-icon {
                    fill: #2468f2 !important;
                }
            }
            cursor: pointer;
            &:first-child,
            &:last-child {
                line-height: 26px;
            }
            .s-icon {
                width: 16px;
                fill: #151b26;
                vertical-align: middle;
            }
        }
        .activePage {
            background: #ffffff;
            border: 1px solid #2468f2;
            color: #2468f2;
        }
        .disabledPage {
            color: #00000040;
            border-color: #d9d9d9;
            cursor: not-allowed;
            .s-icon {
                cursor: not-allowed;
                fill: #b8babf !important;
            }
            &:hover {
                .s-icon {
                    cursor: not-allowed;
                    fill: #b8babf !important;
                }
            }
        }
    }
    &_go {
        display: flex;
        align-items: center;
        &_text {
            font-size: 12px;
            color: #84868c;
            line-height: 20px;
            padding-right: 8px;
            white-space: nowrap;
        }
        &_input {
            display: flex;
            div {
                border-radius: 4px 0 0 4px;
                border: 1px solid #e8e9eb;
                input {
                    border: none;
                    outline: none;
                    border-radius: 4px 0 0 4px;
                    height: 30px;
                    line-height: 32px;
                    padding: 0 10px;
                }
            }
            span {
                border-radius: 0 4px 4px 0;
                border: 1px solid #e8e9eb;
                border-left: none;
                padding: 0 10px;
                font-size: 12px;
                line-height: 30px;
                height: 32px;
                cursor: pointer;
                &:hover {
                    border: 1px solid #2468f2;
                    color: #2468f2;
                }
            }
        }
    }
    &_pagersize {
        position: relative;
        display: flex;
        align-items: center;
        font-size: 12px;
        color: #151b26;
        line-height: 20px;
        border: 1px solid #e8e9eb;
        border-radius: 4px;
        cursor: pointer;
        &_box {
            padding: 0 12px;
            padding-right: 4px;
            height: 32px;
            line-height: 32px;
        }
        .s-icon {
            width: 16px;
            vertical-align: middle;
        }
        & > span {
            display: inline-block;
            height: 100%;
            padding-left: 4px;
            padding-right: 2px;
            margin-right: 10px;
        }
        .pagersize_box_select_option {
            position: absolute;
            display: none;
            padding: 4px 0px;
            width: 100%;
            top: 0px;
            background-color: #ffffff;
            transform: translateY(-102%);
            box-shadow: 0 2px 8px 0 rgba(7, 12, 20, 0.12);
            & > div {
                font-size: 12px;
                padding: 0 10px;
                position: relative;
                white-space: nowrap;
                text-overflow: ellipsis;
                height: 30px;
                line-height: 30px;
                box-sizing: border-box;
                cursor: pointer;
                &:hover {
                    background: #e6f0ff;
                }
            }
            .option_action {
                color: #2468f2;
            }
        }
        &:hover {
            .pagersize_box_select_option {
                display: inline-block;
            }
        }
    }
}
.practice_loading {
    height: 400px;
    text-align: center;
    color: #aaafbf;
    img {
        width: 200px;
    }
}
