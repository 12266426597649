.service-content-item {
    margin-bottom: 60px;
    padding-bottom: 60px;
    border-bottom: 1px solid #eee;
    font-size: 14px;
    &:last-child {
        margin-bottom: 0;
    }
    .top-wrapper {
        height: 58px;
        .title {
            font-size: 22px;
            float: left;
        }

        .understand {
            // display: inline-block;
            text-align: center;
            width: 104px;
            height: 32px;
            line-height: 32px;
            border: 1px solid #1a73e8;
            color: #1a73e8;
            float: right;

            &:hover {
                color: #1c88fc;
                border-color: #1c88fc;
            }
        }
    }
    .content-wrapper {
        display: flex;
        .left {
            width: 440px;
            img {
                width: 100%;
            }
        }

        .right {
            padding-left: 30px;
            width: 710px;

            .list-desc-item {
                margin-bottom: 30px;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            .title {
                font-size: 16px;
                line-height: 24px;
                margin-bottom: 14px;
            }

            .desc {
                color: #666;
                line-height: 22px;
            }
        }
    }
}
