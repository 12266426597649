.frame_md {
    margin-top: 16px;
    p {
        line-height: 22px;
        font-size: 14px;
        color: #5c5f66;
        strong {
            color: #000;
            display: inline-block;
            padding: 8px 0px;
        }
        a {
            color: #2468f2;
        }
    }
    ul {
        height: auto;
        padding-left: 20px;
        list-style: disc;
        li {
            margin-top: 12px;
        }
    }
}
.frame {
    &_desc {
        padding-bottom: 24px;
        margin-bottom: 24px;
        border-bottom: 1px solid #e8e9eb;
        &_title {
            position: relative;
            h3 {
                font-size: 20px;
                color: #151B26;
                line-height: 26px;
                display: inline-block;
                margin-right: 24px;
            }
            &_text {
                font-family: PingFangSC-Medium;
                font-size: 14px;
                color: #151B26;
                line-height: 22px;
            }
        }
        &_content {
            overflow: hidden;
            &>p {
                a {
                    font-size: 14px;
                    color: #2468F2;
                    line-height: 22px;
                }
            }
        }
        &_on,
        &_off {
            position: absolute;
            top: 2px;
            right: 0px;
            font-size: 14px;
            color: #151b26;
            cursor: pointer;
            line-height: 20px;
            &::after {
                content: '';
                display: inline-block;
                width: 10px;
                height: 10px;
                margin-left: 4px;
                background-image: url(assets/bml/paddle/pack_up.svg);
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
            }
            &:hover {
                color: #2468f2;
                &::after {
                    background-image: url(assets/bml/paddle/pack_up_action.svg);
                }
            }
        }
        &_off {
            &::after {
                background-image: url(assets/bml/paddle/unfold.svg);
            }
            &:hover {
                &::after {
                    background-image: url(assets/bml/paddle/unfold_action.svg);
                }
            }
        }
    }
    &_steps {
        margin-top: 12px;
        &_title {
            margin-bottom: 16px;
            font-weight: 600;
            font-size: 16px;
            color: #151b26;
            line-height: 26px;
        }
        .frame_steps_content {
            height: 88px;
            display: flex;
            &_left {
                position: relative;
                text-align: right;
                padding-right: 8px;
                width: 80px;
                height: 100%;
                border-right: 1px solid #2468f2;
                font-size: 14px;
                color: #151b26;
                text-align: right;
                line-height: 22px;
                span {
                    display: inline-block;
                    transform: translateY(-16%);
                    width: max-content;
                }
                i {
                    display: inline-block;
                    background-color: #fff;
                    padding: 4px;
                    position: absolute;
                    top: 0px;
                    right: 0px;
                    line-height: 0px;
                    transform: translate(50%, 0%);
                    &::after {
                       display: inline-block;
                        content: '';
                        width: 6px;
                        height: 6px;
                        border: 1px solid #2468f2;
                        //dotted
                        border-radius: 50%;
                    }
                }
            }
            &_right {
                text-align: left;
                padding-left: 8px;
                height: 100%;
                font-size: 14px;
                color: #151b26;
                line-height: 22px;
                &_box {
                    transform: translateY(-10%);
                    .steps_right_box_title {
                        span {
                            display: inline-block;
                            padding: 0px 7px;
                            font-size: 12px;
                            color: #2468f2;
                            line-height: 20px;
                            border: 1px solid #2468f2;
                            border-radius: 2px;
                            margin-left: 4px;
                        }
                    }
                    .steps_right_box_desc {
                        font-size: 12px;
                        color: #84868c;
                        line-height: 20px;
                    }
                }
            }
            &:last-child {
                height: auto;
                .frame_steps_content_left {
                    border-right: none;
                }
            }
        }
        .frame_steps_content_action {
            .frame_steps_content_left {
                color: #2468f2;
                i {
                    &::after {
                        background-color: #2468f2;
                    }
                }
            }
            .frame_steps_content_right {
                .steps_right_box_title {
                    color: #2468f2;
                }
            }
        }
        .frame_steps_content_dim {
            .frame_steps_content_left {
                color: #84868C;
                border-right: 1px dotted #2468f2;
                i {
                    &::after {
                        border: 1px dotted #2468f2;
                    }
                }
            }
            .frame_steps_content_right {
                .steps_right_box_title {
                    color: #84868C;
                }
            }
        }
    }
}
