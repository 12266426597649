@basic_blue: #036fe2;
@basic_blue_hover: #1c88fc;

.easy {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    color: #000;

    .column3 {
        width: 33.3%;
    }

    .column2 {
        width: 50%;
    }

    .icon-item {
        position: relative;
        font-size: 14px;
        margin-bottom: 90px;
        padding: 0 50px;
        display: flex;
        flex-direction: column;

        &.column3:nth-last-of-type(1),
        &.column3:nth-last-of-type(2),
        &.column3:nth-last-of-type(3) {
            margin-bottom: 0;
        }

        &.column2:nth-last-of-type(1),
        &.column2:nth-last-of-type(2) {
            margin-bottom: 0;
        }

        .icon {
            font-size: 30px;
            position: absolute;
            top: 0;
            left: 0;
        }

        .title {
            font-size: 22px;
            // margin-bottom: 20px;
        }

        .desc {
            margin-top: 16px;
            line-height: 24px;
            font-size: 14px;
            flex: 1;
        }

        .links {
            margin-top: 20px;

            .links_href {
                color: #0073eb;
                margin-right: 30px;
                cursor: pointer;

                &:hover {
                    border-bottom: 1px solid;
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    .not-margin {
        margin-bottom: 0;
    }

    .card-item {
        position: relative;
        font-size: 14px;
        margin-bottom: 20px;
        padding: 0 50px;
        color: #000;

        .title {
            font-size: 22px;
            line-height: 30px;
            position: relative;
            white-space: nowrap;
            font-weight: 500;
        }

        .desc {
            font-size: 14px;
            color: #666666;
            letter-spacing: 0;
            line-height: 24px;
            margin-top: 10px;
            padding-bottom: 14px;
            border-bottom: 2px solid transparent;
        }

        &:hover {
            .title {
                color: @basic_blue;
            }

            .desc {
                border-bottom: 2px solid @basic_blue;
            }
        }
    }

    .intro-item {
        color: #000;
        position: relative;
        height: 200px;
        margin-bottom: 20px;
        margin-right: 20px;
        padding: 30px 50px 0;
        border: 1px solid #eee;
        vertical-align: top;
        cursor: pointer;

        &.column3 {
            width: 380px;
        }

        &.column2 {
            width: 570px;
        }

        &:last-child {
            margin-right: 0;
        }

        &:hover {
            .link {
                border-bottom: 1px solid @basic_blue;
            }
        }

        .title {
            font-size: 22px;
            line-height: 38px;
        }

        .desc {
            line-height: 24px;
            margin-top: 10px;
            font-size: 14px;
        }

        .link {
            position: absolute;
            bottom: 40px;
            left: 50px;
            font-size: 14px;
            height: 16px;
            color: @basic_blue;
            cursor: pointer;

            &::after {
                content: '';
                position: absolute;
                top: 4px;
                display: inline-block;
                width: 7px;
                height: 7px;
                border-top: 1px solid @basic_blue;
                border-right: 1px solid @basic_blue;
                transform: rotate(45deg);
                margin: 0 0 1px 2px;
            }
        }
    }
}

.card-intro {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;

    &-item {
        background: #f8f8f8;
        display: flex;
        flex-flow: column nowrap;
        border-bottom: 2px solid transparent;

        &:last-child {
            margin-right: 0;
        }

        &:hover {
            background: #ffffff;
            border-color: #1a73e8;
            box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);

            .card-intro_title {
                color: #1a73e8;
            }

            .card-intro_desc {
                color: #000;
            }
        }
    }

    &-related,
    &-advantage {
        width: 285px;
        height: 315px;
        padding: 50px 30px;
        margin-right: 20px;
    }

    &-advantage {
        background: #f4f5f6;
    }

    &-deliver {
        width: 380px;
        height: 385px;
        margin-right: 30px;
        padding: 60px 60px 58px 60px;
    }

    &_icon {
        margin-top: 0;
        margin-bottom: 26px;
        font-size: 25px;

        &>img {
            // height: 30px;
            width: 60px;
        }
    }

    &_title {
        font-size: 24px;
        color: #000000;
        letter-spacing: 0;
        line-height: 24px;
        margin-bottom: 26px;
    }

    &_desc {
        font-size: 14px;
        color: #666666;
        letter-spacing: 0;
        text-align: justify;
        line-height: 26px;
        flex-grow: 1;
    }

    &_link {
        font-size: 16px;
        color: #1a73e8;
        letter-spacing: 0;
        line-height: 24px;
        display: flex;
        align-items: center;

        i {
            padding-left: 8px;
            font-size: 12px;
        }
    }
}

.iconHoverCards {
    display: flex;
    width: 100%;
    flex-wrap: wrap;

    .column3 {
        width: 33.3%;
    }

    .column2 {
        width: 50%;
    }

    .card-item {
        display: block;
        position: relative;
        font-size: 14px;
        margin-bottom: 30px;
        padding: 30px 30px 32px 80px;
        display: flex;
        flex-direction: column;
        border-bottom: 2px solid transparent;

        &:hover {
            border-bottom: 2px solid #1a73e8;
            box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);

            .title {
                color: #1a73e8;
            }

            .desc {
                color: #000;
            }
        }

        &.column3:nth-last-of-type(1),
        &.column3:nth-last-of-type(2),
        &.column3:nth-last-of-type(3),
        &.column2:nth-last-of-type(1),
        &.column2:nth-last-of-type(2) {
            margin-bottom: 0;
        }

        .img-icon {
            height: 30px;
            width: 30px;
            position: absolute;
            top: 34px;
            left: 30px;
        }

        .title {
            margin-bottom: 20px;
            font-size: 24px;
            line-height: 38px;
        }

        .desc {
            margin-top: 0;
            margin-bottom: 30px;
            line-height: 26px;
            font-size: 14px;
            flex: 1;
            color: #666;

            b {
                font-weight: 500;
            }
        }

        .link {
            margin-top: 0;
            font-size: 14px;
            color: #1a73e8;
            line-height: 24px;

            &>i {
                font-size: 12px;
                vertical-align: top;
            }
        }
    }
}


.codelab {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;

    &-item {
        // background: #f8f8f8;
        display: flex;
        flex-flow: row nowrap;
        box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
        padding: 30px;
        width: 48%;

        &_img {
            width: 280px;
            height: 150px;
            padding-right: 20px;
        }

        &-info {
            width: 220px;
        }

        &_title {
            font-size: 20px;
            color: #000000;
            letter-spacing: 0;
            margin-bottom: 20px;
        }

        &_intro {
            font-size: 14px;
            color: #666666;
            letter-spacing: 0;
            text-align: justify;
            line-height: 26px;
        }
    }
}

.paddle {
    display: flex;
    flex-flow: row wrap;

    &-item {
        position: relative;
        width: 50%;
        color: #000000;
        letter-spacing: 0;
        display: flex;
        padding: 10px 70px 10px 10px;
        margin-bottom: 50px;

        &_icon {
            width: 26px;
            height: 26px;
            position: absolute;
            top: 20px;
            left: 20px;
        }

        &-info {
            padding-left: 50px;

            &_title {
                font-size: 22px;
                line-height: 38px;
                font-weight: 500;
            }

            &_desc {
                font-size: 14px;
                line-height: 24px;
                font-weight: 400;
            }
        }
    }

    &:nth-last-child(-n+2) {
        // margin-bottom: 0;
        color: red;
    }
}

.chipDataCard {
    width: 1200px;
    height: 409px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 69px;
    .chipDataCard-left {   
        div {
            margin: 31px 0;
            width: 496px;
            padding-top: 18px;
            box-sizing: border-box;
            font-size: 26px;
            color: #000000;
            letter-spacing: 0.8px;
            text-align: justify;
            line-height: 46px;
            font-weight: 400;
            p {
                word-spacing: 10px;
            }
        }

        a {
            margin-top: 0;
            font-size: 16px;
            color: #036FE2;
            letter-spacing: 1.14px;
            text-align: justify;
            line-height: 26px;
            font-weight: 400;

            &>i {
                font-size: 12px;
                margin-left: 10px;
                vertical-align: middle;
                margin-bottom: 2px;
            }
        }
    }
}

.client {
    display: flex;
    flex-flow: row wrap;

    &>div:first-child {
        margin-left: 0;
    }

    &>div:nth-child(4n) {
        margin-left: 0;
    }

    .client-item {
        width: 380px;
        box-sizing: border-box;
        padding: 29px 31px 31px 29px;
        margin-left: 30px;
        margin-bottom: 31px;
        position: relative;
        overflow: hidden;
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);

        .client-item-img {
            width: 200px;
            height: 64px;
            margin-bottom: 20px;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .client-item-con {
            h3 {
                font-size: 20px;
                line-height: 32px;
                font-weight: 500;
                margin-bottom: 20px;
            }

            span {
                display: inline-block;
                background: #EEEFF4;
                padding: 0 12px;
                font-size: 14px;
                line-height: 24px;
                font-weight: 400;
            }

            p {
                margin: 30px 0;
                font-size: 14px;
                color: #666666;
                line-height: 24px;
                font-weight: 400;
            }
        }

        &::before {
            content: '';
            position: absolute;
            width: 260px;
            height: 152px;
            top: -10px;
            left: -60px;
            z-index: -1;
        }

        &:hover::before {
            background-image: linear-gradient(155deg, #6fadff 0%, #FFFFFF 50%);
        }
    }
}

.card-intro-apply {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;

    &-item {
        display: flex;
        flex-flow: column nowrap;
        border-bottom: 2px solid transparent;

        &:last-child {
            margin-right: 0;
        }

        &:hover {
            background: #ffffff;
            box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
            outline-style: solid !important;
            outline-color: #1a73e8 !important;
            outline-width: 1px !important;

            .card-intro_title {
                color: #1a73e8;
            }

            .card-intro_desc {
                color: #000;
            }
        }
    }

    &-deliver {
        width: 380px;
        height: 415px;
        margin-right: 30px;
        padding: 40px 30px 38px 30px;
        box-sizing: content-box;
    }

    &_link {
        border-top: 1px solid #DADCDF;
        font-size: 14px;
        color: #666666;
        letter-spacing: 0;
        text-align: justify;
        line-height: 26px;
        font-weight: 400;
        padding-top: 20px;
        margin-top: 20px;

        p {
            margin-bottom: 20px;

            span {
                padding: 3px 10px;
                background: #EEEFF4;
                margin-right: 20px;
            }
        }

        &>span {
            display: inline-block;
            margin-top: 10px;
            color: #1a73e8;
        }
    }
}

.card-Model-nlp {
    padding: 0px 38px;
    display: flex;
    &-img {
        margin-right: 50px;
        img {
            width: 567px;
            height: 334px;
        }
    }
    &-describe {
        padding-top: 30px;
        font-size: 26px;
        color: #000000;
        letter-spacing: 1px;
        text-align: justify;
        line-height: 46px;
        font-weight: 400;

        &-title {
            margin-bottom: 10px;
        }

        a {
            font-size: 16px;
            color: #036FE2;
            letter-spacing: 1.14px;
            line-height: 26px;
            font-weight: 400;
            margin-right: 25px;
            i {
                vertical-align: middle;
                font-size: 12px;
            }
        }
    }
    &-tag {
        margin-top: 46px;
        font-size: 16px;
        color: #000000;
        letter-spacing: 1px;
        line-height: 22px;
        font-weight: 400;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        &>div {
            flex: 1 1 40%;
            margin-bottom: 33px;
            img {
                width: 25px;
                vertical-align: middle;
            }
            span {
                vertical-align: middle;
                margin-left: 15px;
            }
        }
        
    }
}