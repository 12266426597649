@colorMain: #036fe2;
@colorMainWx: #528EFF;
@colorWx: #2468F2;
@colorMainLight: #1c88fc;
@colorDefaultButtonBorder: #e1e1e1;
@colorWhite: #fff;
@colorBlack: #000;

.abutton {
    display: inline-block;
    text-align: center;
    min-width: 130px;
    padding: 8px;
    font-size: 18px;
    line-height: 30px;
    height: 30px;
    box-sizing: content-box;
    cursor: pointer;
    &-default {
        color: @colorBlack;
        background-color: @colorWhite;
        border: 1px solid @colorDefaultButtonBorder;
        &:hover {
            border: 1px solid #ccc;
        }
    }
    &-default.is-plain {
        color: @colorWhite;
        background: rgba(255, 255, 255, 0.1);
        &:hover {
            color: @colorBlack;
            background: @colorWhite;
        }
    }
    &-default-primary {
        color: @colorBlack;
        background-color: @colorWhite;
        border: 1px solid @colorDefaultButtonBorder;
        &:hover {
            border: 1px solid @colorMain;
            color: @colorMain;
        }
    }
    &-default-wx-plain {
        color: #818181;
        border: 1px solid #dcdcdc;
        font-family: PingFangSC-Medium;
        font-size: 16px;
        width: 158px;
        border-radius: 4px;
        height: 22px;
        line-height: 22px;
        min-width: 126px;
    }
    &-default-transparent {
        color: @colorWhite;
        border: 1px solid @colorWhite;
        &:hover {
            border: 1px solid @colorMain;
            color: @colorMain;
        }
    }
    &-default-transparent-light {
        color: @colorBlack;
        border: 1px solid @colorBlack;
        &:hover {
            border: 1px solid @colorMain;
            color: @colorMain;
            background-color: @colorWhite;
        }
    }
    &-default-transparent-white {
        font-family: PingFangSC-Medium;
        font-size: 16px;
        width: 120px;
        border-radius: 4px;
        height: 24px;
        line-height: 22px;
        min-width: 120px;
        color: @colorWx;
        background: #fff;
        &:hover {
            color: @colorMainWx;
        }
    }
    &-primary {
        color: @colorWhite;
        background-color: @colorMain;
        border: 1px solid @colorMain;
        &:hover {
            background: @colorMainLight;
            border-color: @colorMainLight;
        }
    }
    &-primary-wx {
        font-family: PingFangSC-Medium;
        font-size: 16px;
        width: 126px;
        border-radius: 4px;
        height: 22px;
        line-height: 22px;
        min-width: 126px;
        color: @colorWhite;
        background-color: @colorWx;
        border: 1px solid @colorWx;
        &:hover {
            background: @colorMainWx;
            border-color: @colorMainWx;
        }
    }
    &-primary-transparent-wx {
        font-family: PingFangSC-Medium;
        font-size: 16px;
        width: 126px;
        border-radius: 4px;
        height: 22px;
        line-height: 22px;
        min-width: 126px;
        color: @colorWx;
        border: 1px solid @colorWx;
        &:hover {
            border: 1px solid @colorMainWx;
            color: @colorMainWx;
        }
    }
    &-primary.is-plain {
        color: @colorMain;
        background: rgba(3, 111, 226, 0.1);
        &:hover {
            color: @colorWhite;
            background: @colorMainLight;
        }
    }

    &.abutton-round-half {
        border-radius: 24px;
    }
    &.abutton-round-quarter {
        border-radius: 12px;
    }
    &-large {
        font-size: 16px;
        line-height: 36px;
        height: 36px;
    }
    &-large.abutton-round-half {
        border-radius: 29px;
    }
    &-large.abutton-round-quarter {
        border-radius: 15px;
    }
    &-small {
        font-size: 16px;
        line-height: 20px;
        height: 20px;
    }
    &-small.abutton-round-half {
        border-radius: 19px;
    }
    &-small.abutton-round-quarter {
        border-radius: 9px;
    }
    &-mini {
        font-size: 14px;
        line-height: 16px;
        height: 16px;
    }
    &-mini.abutton-round-round {
        border-radius: 17px;
    }
    &-mini.abutton-round-quarter {
        border-radius: 8px;
    }
}
.abutton-grounp {
    &.abutton-grounp-center {
        text-align: center;
    }
    &.abutton-grounp-left {
        text-align: left;
    }
}
