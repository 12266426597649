.offline-modal-content {
    width: 668px;
    font-size: 12px;
    // border: 1px solid #e8e9eb;
    // border-radius: 4px;

    font-size: 12px;
    color: #151b26;
    text-align: justify;
    line-height: 20px;
    font-weight: 400;
    padding: 0 16px;

    // .tip-title {
    //     font-weight: bold;
    //     font-size: 14px;
    //     padding: 0 0 12px;
    //     text-align: center;
    // }
    p,
    ul,
    ol {
        margin-bottom: 24px;
    }
    a {
        color: unset;
        text-decoration: unset;
    }

    .footer {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        button {
            flex: none;
            cursor: pointer;
            line-height: 30px;
            border: none;
            background-color: rgb(36, 104, 242);
            &:hover {
                background-color: rgb(82, 142, 255);
            }
            border-radius: 4px;
            color: #fff;
            padding: 0 16px;
        }
    }
}
