.template_contnet {
    width: 100%;
    display: flex;
    // flex-flow: row wrap-reverse;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
}

.template_item {
    margin-bottom: 30px;
    height: 458px;
    background: #ffffff;
    border: 1px solid #eeeeee;
    cursor: pointer;
    &.odd {
        margin-right: 30px;
        width: 373px;
        &:nth-last-of-type(1),
        &:nth-last-of-type(2),
        &:nth-last-of-type(3) {
            margin-bottom: 0;
        }
        &:nth-of-type(3n) {
            margin-right: 0;
        }
    }

    &.even {
        width: 575px;
        margin-right: 30px;
        &:nth-last-of-type(1),
        &:nth-last-of-type(2) {
            margin-bottom: 0;
        }
        &:nth-of-type(2n) {
            margin-right: 0;
        }
    }

    // &:last-child {
    //     margin-right: 0;
    // }

    .img-wrapper {
        width: 100%;
        height: 225px;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .detail {
        padding: 20px 30px 30px 30px;
        font-size: 14px;
        display: flex;
        flex-direction: column;
        height: 234px;
        .title {
            font-size: 22px;
            line-height: 38px;
            color: #000;
            letter-spacing: 1px;
            margin-bottom: 10px;
        }
        .desc {
            font-size: 14px;
            color: #666;
            letter-spacing: 0;
            line-height: 24px;
            flex-grow: 1;
        }
        .keywords {
            display: flex;
            flex-flow: row wrap;
            margin-bottom: 20px;
            .keyword {
                color: #333;
                letter-spacing: 0;
                padding: 2px 12px;
                background-color: #f7f7f7;
                margin-right: 10px;
            }
        }
        .link {
            color: #0073eb;
            letter-spacing: 0;
            text-align: left;
            line-height: 24px;
        }
    }

    &:hover {
        box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
        .detail .link {
            text-decoration: underline;
        }
    }
}
