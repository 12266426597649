.productForm {
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    .item {
        display: flex;
        flex-direction: column;
        width: 530px;
    }
    .title {
        font-size: 22px;
        color: #000;
        letter-spacing: 0;
        line-height: 38px;
        font-weight: 700;
    }
    .desc {
        font-size: 14px;
        color: #000;
        line-height: 24px;
        margin-top: 10px;
        flex: 1;
    }
    .link-wrap {
        padding-top: 14px;
    }
    .link {
        display: inline-block;
        margin-right: 30px;
        font-size: 14px;
        color: #036fe2;
        cursor: pointer;
        &:hover {
            text-decoration: underline;
        }
    }
}
