.all_scene {
    margin-top: -20px;
    .all_scene_screen {
        .scene_screen {
            margin:  16px 0;
            display: flex;
            align-items: center;
            flex-flow: row nowrap;
            font-family: PingFangSC-Regular;
            font-size: 14px;
            color: #000000;
            letter-spacing: 0;
            line-height: 14px;
            font-weight: 400;
            ul {
                width: 80%;
                margin-left: 12px;
                display: flex;
                flex-flow: row;
                align-items: center;
                li {
                    // flex: 1 1 14%;
                    width: 134px;
                }
            }
            .scene_screen_item {
                padding: 2px 12px;
                cursor: pointer;
            }
            .active_item {
                background: #1A73E8;
                color: #fff;
            }
        }
    }
    .all_scene_content {
        margin-top: 32px;
        .scene_content_search {
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;
            .scene_search {
                width: 213px;
                height: 32px;
                position: relative;
                background: #FFFFFF;
                border: 1px solid rgba(224,224,224,1);
                border-radius: 3.56px;
                padding: 5px 12px;
                .scene_search_input {
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: space-between;
                    padding-top: 2px;
                    .scene_search_input_close {
                        padding: 2px;
                        border-radius: 50%;
                        background: #dad8d8;
                    }
                    img {
                        width: 14px;
                        height: 14px;
                    }
                }
                input {
                    padding: 0;
                    border: none;
                    outline: none;
                }
                .scene_search_ul {
                    position: absolute;
                    top: 34px;
                    left: -1px;
                    z-index: 999;
                    width: 213px;
                    height: 144px;
                    background: #FFFFFF;
                    overflow-y: auto;
                    box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.1);
                    padding-top: 12px;
                    li {
                        font-family: PingFangSC-Regular;
                        height: 32px;
                        font-size: 12px;
                        color: #999999;
                        line-height: 20px;
                        font-weight: 400;
                        padding: 0 16px;
                        cursor: pointer;
                        span {
                            display: inline-block;
                            margin-left: 10px;
                            font-size: 12px;
                            color: #1F1F1F;
                            line-height: 20px;
                            font-weight: 400;
                            cursor: pointer;
                        }
                    }
                }
            }
            .activate {
                border: 1px solid rgba(26,115,232,1);
            }
            .scene_content_search_pagination {
                font-size: 14px;
                color: #000000;
                letter-spacing: 0;
                text-align: center;
                line-height: 22px;
                .disabledPage {
                    color: #A6A6A6;
                }
            }
        }
        .scene_content_box {
            display: flex;
            flex-flow: row wrap;
            .scene_content_box_item {
                position: relative;
                width: 280px;
                height: 290px;
                overflow: hidden;
                margin-bottom: 20px;
                margin-right: 20px;
                border-bottom: 1px solid rgba(218,220,223,1);
                border-top: 1px solid rgba(218,220,223,1);
                &:nth-child(4n) {
                    margin-right: 0px;
                }
                img {
                    width: 100%;
                    height: 188px;
                }
                &:hover>.box_item {
                    top: -1px;
                    .box_item_tag {
                        white-space: normal;
                    }
                }
                .box_item {
                    position: absolute;
                    border: 1px solid rgba(218,220,223,1);
                    border-bottom: none;
                    top: 188px;
                    left: 0px;
                    background: #fff;
                    height: 290px;
                    padding-top: 24px;
                    display: flex;
                    flex-flow: column nowrap;
                    justify-content: space-between;
                    transition: all .5s;
                    p {
                        margin: 0px 24px;
                    }
                    .box_item_industry {
                        font-size: 20px;
                        color: #000000;
                        letter-spacing: 0;
                        line-height: 24px;
                        font-weight: 500;
                    }
                    .box_item_tag {
                        width: 230px;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                        font-size: 14px;
                        color: #666666;
                        font-weight: 400;
                        margin: 14px 24px;
                    }
                    .box_item_desc {
                        flex-grow: 1;
                        font-size: 14px;
                        color: #000000;
                        text-align: justify;
                        font-weight: 400;
                    }
                    .box_item_buttom {
                        height: 60px;
                        text-align: center;
                        background: #1A73E8;
                        font-size: 20px;
                        color: #FFFFFF;
                        cursor: pointer;
                        letter-spacing: 0;
                        line-height: 60px;
                        font-weight: 400;
                    }
                }

            }
            .scene_content_box_error {
                width: 160px;
                margin: 0 auto;
                text-align: center;
                font-family: PingFangSC-Regular;
                font-size: 16px;
                color: #666666;
                line-height: 24px;
                font-weight: 400;
                img {
                    width: 100%;
                }
            }
        }
        .scene_content_footer {
            margin-top: 35px;
            .scene_pagination {
                display: flex;
                justify-content: center;
                flex-flow: row nowrap;
                font-family: PingFangSC-Regular;
                font-size: 14px;
                color: #333333;
                letter-spacing: 0;
                text-align: center;
                line-height: 30px;
                font-weight: 400;
                li {
                    width: 30px;
                    height: 30px;
                    margin-left: 4px;
                    border: 1px solid rgba(224,224,224,1);
                    cursor: pointer;
                    &:first-child, &:last-child {
                        line-height: 26px;
                    }
                }
                .activePage {
                    background: #F2F5FF;
                    border: 1px solid rgba(76,132,255,1);
                    color: #4C84FF;
                }
                .disabledPage {
                    color: #00000040;
                    border-color: #d9d9d9;
                    cursor: not-allowed;
                }
            }
        }
    }

}
.table_scene {
    border-spacing: 0;
    border-collapse: collapse;
    &_thead {
        background: #f2f2f4;
        color: #5e626a;

        th {
            text-align: left;
            border-right: 1px solid #fff;
            height: 40px;
            border-radius: 4px 0 0 0;
        }
    }
    &_tbody {
        background-color: #fff;
        &_box {
            border-bottom: 1px solid #e6e6e8 !important;
            text-align: left;
            font-weight: normal;
            vertical-align: middle;
            td {
                padding: 8px 0px;
                span {
                    color: #f3946c;
                }
            }
        }
    }
    th, td {
        padding-left: 8px;
    }
}