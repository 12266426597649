.download {
    display: flex;

    &-item {
        height: 415px;
        width: 33.33%;
        letter-spacing: 0;
        border-left: 1px solid #eeeeee;
        padding: 40px;

        &:first-child {
            border-left: none;
        }
        .header {
            display: flex;
            margin-bottom: 45px;
            .icon {
                width: 35px;
                height: 35px;
                margin-right: 20px;
            }
            .type {
                font-size: 20px;
                color: #000000;
                font-weight: 500;
                line-height: 45px;
            }
        }
        .body {
            height: 213px;
            &-info {
                margin-bottom: 15px;
                font-size: 14px;
                line-height: 22px;
                font-weight: 400;

                &_key {
                    color: #666666;
                }
                &_value {
                    color: #000000;
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
        .footer {
            display: flex;
            justify-content: space-around;
            .download-btn {
                cursor: pointer;
                height: 48px;
                line-height: 48px;
                width: 100%;
                font-size: 14px;
                color: #ffffff;
                letter-spacing: 0;
                text-align: center;
                font-weight: 500;
                background: #1a73e8;
                border: 1px solid #1a73e8;
                margin-right: 16px;
                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
}
