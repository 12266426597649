.feature {
    &-item {
        display: flex;
        margin-bottom: 60px;
        &:last-child {
            margin-bottom: 0;
        }
        .txt-area {
            // padding: 60px;
            padding: 60px 60px 0 60px;
            width: calc(100% - 600px);

            .title {
                font-size: 22px;
                color: #000000;
                letter-spacing: 0;
                line-height: 38px;
            }

            .desc {
                font-size: 16px;
                color: #666666;
                letter-spacing: 0;
                line-height: 28px;
                margin-top: 20px;
            }

            .link {
                display: inline-block;
                margin-top: 40px;
                width: 116px;
                height: 38px;
                font-size: 14px;
                color: #006fef;
                letter-spacing: 0;
                text-align: center;
                line-height: 36px;
                border: 1px solid #006fef;
            }
        }
        .img-area {
            flex-basis: 600px;
            img {
                width: 100%;
            }
        }

        .right {
            order: 1;
        }

        .text-link {
            display: inline-block;
            width: auto;
            padding: 0 10px;
            margin-left: 15px;
            line-height: 36px;
            color: var(--swiper-theme-color);
            font-size: 14px;
            border: solid 1px currentColor;
        }
    }
}
