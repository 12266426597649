@input_height: 40px;

.placeholder-init {
    &::-webkit-input-placeholder {
        color: #ccc;
    }

    &::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: #ccc;
    }

    &:-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: #ccc;
    }

    &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #ccc;
    }
}

.scroll-init {
    &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background-color: #ccc;
    }

    &::-webkit-scrollbar-track {
        background-color: transparent;
    }
}

.form-item {
    margin-bottom: 20px;
    display: flex;
    font-size: 14px;

    &-required {
        .form-item-label {
            &::before {
                content: '*';
                color: #e93d34;
                margin-right: 4px;
                vertical-align: middle;
            }
        }
    }

    &-label {
        position: relative;
        width: 130px;
        margin-right: 35px;
        line-height: @input_height;
        text-align: right;
        white-space: nowrap;
        overflow: hidden;
    }

    &-content {
        width: 400px;
    }
    &-children {
        display: flex;
    }
    &-desc {
        white-space: nowrap;
        color: #999;
        line-height: 40px;
    }

    &-input,
    &-textarea,
    &-select {
        font-size: 12px;
        outline: none;
        border: 1px solid #e1e1e1;
        width: 100%;
        padding: 5px;

        &:hover {
            border: 1px solid #f2f2f2;
        }

        &:focus {
            border: 1px solid #000;
        }
    }
    &-radio {
        width: 18px;
        height: 18px;
        margin-right: 10px;
        line-height: 40px;
        &:last-child {
            margin-left: 20px;
        }
        .radio-item {
            margin-left: 10px;
        }
    }

    &-select,
    &-input {
        height: @input_height !important;
    }
    &-tip {
        color: red;
        margin-top: 10px;
    }
    &-textarea {
        height: 120px;
        line-height: 18px;
        padding: 5px 10px;
        .placeholder-init();
        .scroll-init();
    }
    .verify-code {
        width: 150px;
        height: 40px;
        cursor: pointer;
        font-size: 14px;
        margin-left: 20px;
    }
    .submit {
        width: 130px;
        height: 38px;
        line-height: 38px;
        font-size: 16px;
        text-align: center;
        color: #fff;
        background: #036fe2;
        cursor: pointer;

        &:hover {
            background: #1c88fc;
        }
    }

    .text {
        line-height: 40px;
    }
}

.checkbox-group {
    flex: 1;
    display: flex;
    flex-direction: column;
}
.form-item-checkbox {
    width: 100%;
    display: flex;
    .checkbox {
        height: 40px;
    }
    .checkbox-label {
        flex: 1;
        line-height: 40px;
        margin-left: 8px;
    }
}
