.application-body {
    background: #fff;
    .application-title {
        width: 100%;
        font-size: 36px;
        padding-top: 60px;
        margin: 0 0 60px;
        text-align: center;
        font-weight: 400;
        letter-spacing: initial;
    }

    .application-content {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-around;
        color: #000;
        margin-bottom: 0;
        background: #fff;
    }
}

.scene-tab {
    display: flex;
    text-align: center;
    margin-bottom: 40px;
    white-space: nowrap;

    &-item,
    &-link {
        font-size: 16px;
        text-align: center;
        margin-right: 78px;
        cursor: pointer;
        color: #686868;
        &:hover {
            color: #187df5;
            transition: all 0.2s;
        }

        .icon {
            font-size: 30px;
            margin-bottom: 20px;
        }

        &.active {
            color: #036fe2;
        }

        &:last-child {
            margin-right: 0;
        }
    }
}

.scene-detail {
    background: #f9fafe;
    width: 100%;
    .scene-content {
        width: 1180px;
        margin: 0 auto;
    }
    &-item {
        padding-top: 60px;
        margin-bottom: 40px;
        font-size: 0;
        &:last-child {
            padding-bottom: 60px;
            margin-bottom: 0;
        }
        &-left {
            display: inline-block;
            width: 520px;
            font-size: 14px;
            vertical-align: top;

            .title {
                font-size: 22px;
                font-weight: 400;
                margin-bottom: 29px;

                a {
                    color: #000;
                    text-decoration: none;

                    &:hover {
                        color: #187df5;
                    }
                }
            }

            .desc {
                line-height: 24px;
            }

            .example {
                line-height: 14px;
                color: #777;
                margin: 20px 0 30px;
                white-space: nowrap;
            }

            .img-wrapper {
                width: 100%;

                .supplier-item {
                    display: inline-block;
                    height: 30px;
                    margin-right: 30px;
                    background: url(../../assets/application.png);
                    background-size: cover;
                    background-repeat: no-repeat;
                    &:last-child {
                        margin-right: 0;
                    }
                }
                .origin {
                    // 源创
                    width: 74px;
                    background-position: -336px 0 !important;
                }

                .idcool {
                    // 家图网
                    width: 94px;
                    background-position: -577px 0 !important;
                }

                .bob {
                    width: 70px;
                    background-position: -266px 0 !important;
                }

                .cella {
                    width: 80px;
                    background-position: -411px 0 !important;
                }

                .leet {
                    // 俪特地产
                    width: 123px;
                    background-position: -779px 0 !important;
                }

                .southern {
                    // 南方电网
                    width: 88px;
                    background-position: -490px 0 !important;
                }

                .yihang {
                    width: 109px;
                    background-position: -671px 0 !important;
                }

                .shihuo {
                    // 识货
                    width: 45px;
                    background-position: -107px 0 !important;
                }

                .wuhucj {
                    // 芜湖储吉
                    width: 52px;
                    background-position: -152px 0 !important;
                }

                .edianjia {
                    // e店佳
                    width: 61px;
                    background-position: -205px 0 !important;
                }

                .focusbear {
                    width: 32px;
                    background-position: -30px 0 !important;
                }

                .leapLearner {
                    // 立乐教育
                    width: 43px;
                    background-position: -63px 0 !important;
                }

                .butterflyFish {
                    background: url(../../assets/case/ipack.jpg) !important;
                    width: 60px;
                    background-size: cover !important;
                    background-position: center !important;
                }
            }
        }

        &-right {
            display: inline-block;
            vertical-align: top;

            .detail-wrapper {
                width: 600px;
                height: 220px;
                margin-left: 60px;

                img {
                    width: 100%;
                }
            }
        }
    }
}

.scene-more {
    background: #f9fafe;
    width: 100%;
    .scene-content {
        width: 1180px;
        margin: 0 auto;
    }
    &-content {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
    }
    &-item {
        width: 33%;
        font-size: 14px;
        margin-bottom: 92px;
        vertical-align: top;
        .title {
            font-size: 18px;
            color: #333;
            margin-bottom: 19px;
            font-weight: 400;
        }

        .desc {
            font-size: 14px;
            width: 310px;
            color: #999;
            line-height: 22px;
        }
    }
}
