.exploit {
    &_md {
        div[align="left"], div[align="center"] {
            &>img {
                height: 140px;
            }
        }
        h2,h3 {
            margin-bottom: 12px;
        }
        strong {
            font-size: 14px;
            color: #151B26;
            line-height: 24px;
        }
        h5, h3 {
            margin: 24px 0px 16px;
        }
        h2 {
            font-family: PingFangSC-Medium;
            font-size: 20px;
            color: #151B26;
            line-height: 26px;
        }
        h3 {
            font-family: PingFangSC-Medium;
            font-size: 16px;
            color: #151B26;
            line-height: 24px;
        }
        ul {
            list-style: disc;
            padding-left: 20px;
            padding-bottom: 10px;
            li {
                margin-top: 12px;
                font-family: PingFangSC-Regular;
                font-size: 14px;
                color: #5C5F66;
                line-height: 22px;
                &>ul {
                    list-style: circle;
                }
            }
            a {
                color: #144bcc;
            }
        }
        p {
            margin: 4px 0px;
            font-family: PingFangSC-Regular;
            font-size: 14px;
            color: #5C5F66;
            line-height: 22px;
            a {
                color: #144bcc;
                strong {
                    color: #144bcc;
                }
            }
        }
        img {
            width: auto;
        }
        blockquote {
            border-left: 4px solid #d0d7de;
            padding-left: 8px;
            a {
                color: #0969da;
            }
        }
        table {
            display: block;
            width: max-content;
            max-width: 100%;
            overflow: auto;
            border-spacing: 0;
            border-collapse: collapse;
            tr {
                background-color: #ffffff;
                border-top: 1px solid #d0d7de;
                th, td {
                    padding: 6px 13px;
                    border: 1px solid #d0d7de;
                }
            }
        }
    }
    &_bottom {
        position: absolute;
        bottom: 0px;
        left: 0px;
        right: 0px;
        padding: 20px 16px;
        display: flex;
        background-color: #FFFFFF;
        span {
            width: 108px;
            height: 40px;
            margin-right: 16px;
            border: 1px solid #144bcc;
            border-radius: 4px;
            font-family: PingFangSC-Regular;
            font-size: 12px;
            color: #144bcc;
            text-align: center;
            line-height: 38px;
            cursor: pointer;
            &:first-child {
                color: #FFFFFF;
                border-width: 0px;
                background: #2468F2;
            }
        }
        &>&_disabled {
            cursor: not-allowed;
            color: #b8babf !important;
            background-color: #f7f7f9 !important;
            border-color: #f7f7f9;
        }
    }
}
