.collect {
    width: 668px;
    height: 500px;
    padding: 0 80px 0 40px;
    overflow: auto;
    &>div {
        flex-flow: column nowrap;
        label {
            width: 100%;
            white-space: normal;
            text-align: left;
        }
        div {
            label {
                margin-left: 20px;
                &:first-child {
                    margin-left: 0px;
                }
            }
        }
    }
    .info {
        font-size: 14px;
        color: #999999;
        line-height: 22px;
        font-weight: 400;
        text-align: center;
        padding-bottom: 30px;
    }
    .submit {
        width: 130px;
        height: 38px;
        line-height: 38px;
        font-size: 16px;
        text-align: center;
        color: #fff;
        background: #036fe2;
        cursor: pointer;
        margin-left: 70px;

        &:hover {
            background: #1c88fc;
        }
    }
}
