.content {
    padding: 20px;
    font-size: 14px;
    width: 300px;
    text-align: center;

    p {
        margin-bottom: 20px;
    }

    button {
        all: unset;
        box-sizing: border-box;
        cursor: pointer;
        text-align: center;
        white-space: nowrap;
        background: #1a73e8;
        color: #fff;
        padding: 0 20px;
        line-height: 32px;
        height: 32px;
    }
}
