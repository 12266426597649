.use_step {
    padding: 0 50px;
    display: flex;
    justify-content: space-between;
    &_item {
        box-sizing: content-box;
        position: relative;
        vertical-align: top;
        &.__column3 {
            width: 280px;
            padding-right: 120px;
        }
        &.__column4 {
            width: 200px;
            padding-right: 93px;
        }
        &.__column5 {
            width: 180px;
        }
        &:last-child {
            padding-right: 0;
            &::after,
            &::before {
                display: none;
            }
        }
        .use_step_index {
            font-size: 48px;
            line-height: 60px;
            display: flex;
            & > span {
                opacity: 0.1;
            }
            .arrow {
                width: 90px;
                display: flex;
                align-items: center;
                margin: 0 auto;
                &::after {
                    content: '';
                    width: 0;
                    height: 0;
                    display: block;
                    border-left: 10px solid #ccc;
                    border-top: 5px solid transparent;
                    border-bottom: 5px solid transparent;
                    border-right: 10px solid transparent;
                }
                &::before {
                    content: '';
                    width: 70px;
                    height: 0;
                    display: block;
                    border-top: 1px dashed #ccc;
                }
            }
        }
        .title {
            font-size: 24px;
            margin: 10px auto 15px;
            line-height: 34px;
            font-weight: 500;
        }
        .maxLimit {
            font-size: 20px;
        }
        .desc {
            font-size: 16px;
            color: #333;
            line-height: 26px;
        }
    }
}

.wenxin {
    padding: 0 3.125%;
    .use_step_item {
        box-sizing: content-box;
        position: relative;
        vertical-align: top;
        &.__column3 {
            width: 29.33%;
            padding-right: 6%;
        }
        &.__column4 {
            width: 20.5%;
            padding-right: 6%;
        }
        &.__column5 {
            width: 17.2%;
            padding-right: 6%;
        }
        .use_step_index {
            justify-content: space-between;
            .arrow {
                margin: 0;
            }
        }
        .title {
            font-size: 22px;
        }
    }
}
