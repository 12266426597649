.section {
    margin: 0 auto;
    font-size: 0;
    &-wrap {
        padding: 60px 0;
        .easydl {
            width: 1180px;
        }
        .wenxin {
            width: 1180px;
            color: #222;
            background: transparent !important;
            .section-header {
                padding-bottom: 32px;
                .section-title {
                    font-size: 28px;
                    font-weight: 500;
                    line-height: 40px;
                }
                .subSection-desc {
                    font-size: 14px;
                    line-height: 24px;
                    padding-top: 12px;
                }
            }
            .use_step {
                padding: 0;
            }
        }
        .wenxin-100 {
            width: 100%;
            color: #222;
            background: transparent !important;
            .section-header {
                padding-bottom: 32px;
                .section-title {
                    font-size: 28px;
                    font-weight: 500;
                    line-height: 40px;
                }
                .subSection-desc {
                    font-size: 14px;
                    line-height: 24px;
                    padding-top: 12px;
                }
            }
            .use_step {
                padding: 0;
            }
        }
        .bml {
            .section-title {
                font-size: 40px;
            }
            width: 1200px;
        }
    }
    &-ghostWhite {
        background: #f8f8f8;
    }
    &-default {
        background: #fff;
        padding: 52px 0;
    }
    &-default-noBottom {
        background: #fff;
        padding: 52px 0 0 0;
    }
    &-blue {
        background: #f2f6fa;
        padding: 52px 0;
    }
    &-deepBlue {
        position: relative;
        background: #2468f2;
        background-image: linear-gradient(107deg, #2f40ff 0%, #2d65ff 38%, #1c7bff 71%, #0789fd 91%, #3882ff 100%);
        padding: 52px 0;
        .section-title {
            font-size: 28px;
            color: #fff;
            line-height: 40px;
            font-weight: 500;
        }
    }
    &-white {
        background: transparent;
    }
    &-codelab {
        background: #f6f8fb;
        background-image: url(../../assets/bml/codelab/bg.png);
        background-position: bottom;
        background-size: 1200px auto;
        background-repeat: no-repeat;
    }
    &-header {
        padding-bottom: 60px;
    }
    &-title {
        width: 100%;
        font-size: 32px;
        text-align: center;
        font-weight: 400;
        letter-spacing: initial;
    }
    &-desc {
        padding-top: 20px;
        font-size: 16px;
        color: #666666;
        text-align: center;
        a {
            color: #0073eb;
            &:hover {
                text-decoration: underline;
            }
        }
    }
    .subSection-header {
        padding-bottom: 80px;
    }
    .subSection-desc {
        padding-top: 30px;
    }
}

.wenxin-start {
    .subSection-header {
        padding-bottom: 30px;
    }
}

