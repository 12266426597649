.paddle_paradigm {
    &_box {
        & > h3 {
            text-align: center;
            font-family: PingFangSC-Regular;
            font-size: 24px;
            color: #000000;
            letter-spacing: 0;
            text-align: center;
            margin-bottom: 30px;
        }
        &_item {
            display: flex;
            flex-flow: row wrap;
            padding-bottom: 60px;
            &_div {
                display: flex;
                &:hover {
                    border: 1px solid #E8E9EB;
                    box-shadow: 0 2px 8px 2px rgba(130,147,171,0.15);
                    span {
                        color: #036FE2;
                    }
                }
            }
            div {
                position: relative;
                border: 1px solid #E8E9EB;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 240px;
                height: 143px;
                margin-left: -1px;
                cursor: pointer;
                img {
                    width: 32px;
                }
                span {
                    display: inline-block;
                    margin-top: 12px;
                    font-family: PingFangSC-Regular;
                    font-size: 18px;
                    color: #000000;
                    letter-spacing: 0;
                    text-align: center;
                    line-height: 24px;
                }
                &:nth-child(n*5){
                    margin-left: 0px;
                }
                &:nth-child(n+5) {
                    margin-top: -1px;
                }
                &>p {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    font-family: PingFangSC-Regular;
                    font-size: 18px;
                    color: #B8BABF;
                    background-color: #fafafc;
                    letter-spacing: 0;
                    text-align: center;
                    line-height: 143px;
                }
            }
        }
        &>img {
            width: 100%;
        }
        &_external {
            position: relative;
            height: 430px;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            &_item {
                position: absolute;
                left: 24%;
                top: 48%;
                transform: translateX(6px);
                display: flex;
                flex-flow: row nowrap;
                .external_item_box {
                    margin-right: 47px;
                    &:last-child {
                        margin-right: 0px;
                    }
                    p {
                        margin-bottom: 12px;
                        font-family: PingFangSC-Regular;
                        font-size: 14px;
                        color: #036FE2;
                        letter-spacing: 0;
                        text-align: center;
                        line-height: 22px;
                        cursor: pointer;
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
            &_item_bottom {
                position: absolute;
                bottom: 27px;
                left: 44%;
                transform: translateX(3px);
                p {
                    margin-bottom: 12px;
                    font-family: PingFangSC-Regular;
                    font-size: 14px;
                    color: #036FE2;
                    letter-spacing: 0;
                    text-align: center;
                    line-height: 22px;
                    cursor: pointer;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}
