.uploader {
    input {
        display: none;
    }
    &_box {
        p {
            font-size: 12px;
            width: max-content;
            color: #909090;
        }
        span {
            display: inline-block;
            padding: 8px 20px;
            border: 1px solid #e1e1e1;
            cursor: pointer;
            margin-bottom: 4px;
        }
    }
    &_file {
        display: flex;
        width: 400px;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;
        padding: 6px 10px;
        background-color: #f3f3f3;
        span {
            &:first-child {
                width: 350px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            &:last-child {
                font-size: 18px;
                cursor: pointer;
            }
        }
    }
    .uploader_error {
        margin-top: 6px;
        color: #dd5d55;
    }
    .error {
        background-color: #fdf0ef;
    }
}