.application-scene {
    .application-scene-tab {
        display: inline-block;
        width: 180px;
        height: 408px;
        background: #f7f7f7;

        &-item {
            font-size: 14px;
            padding-left: 20px;
            margin-bottom: 10px;
            line-height: 24px;
            color: #666;
            cursor: pointer;
            font-weight: 500;

            &:hover {
                color: #1a73e8;
            }

            &:first-child {
                margin-top: 10px;

                &.active {
                    margin-top: 0;
                }
            }

            &.active {
                background-image: linear-gradient(90deg, #2081fc 0%, #036fe2 100%);
                height: 62px;
                line-height: 26px;
                padding: 18px 0 18px 20px;
                font-size: 16px;
                color: #fff;
            }
        }
    }

    .application-scene-content {
        display: inline-block;
        font-size: 0;
        padding-left: 70px;
        vertical-align: top;

        .application-scene-item {
            display: none;

            &:first-child {
                display: block;
            }
        }

        .top {
            height: 198px;
            border-bottom: 1px solid #eee;

            .text-wrapper,
            .img-wrapper {
                display: inline-block;
                vertical-align: top;
            }

            .title {
                display: block;
                font-size: 22px;
                line-height: 38px;
                margin: 12px 0 10px;
                font-weight: 500;
            }

            .desc {
                display: inline-block;
                font-size: 14px;
                line-height: 24px;
                width: 580px;
                padding-right: 70px;
                box-sizing: content-box;
                color: #666666;
            }

            .application-scene-banner {
                width: 280px;
                height: 158px;
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
            }
        }
        .no-bottom-line {
            border-bottom: none;
        }
        .bot {
            height: 209px;

            .title {
                display: inline-block;
                font-size: 22px;
                line-height: 38px;
                margin: 40px 0 20px;
            }

            .user-logo-wrapper {
                .logo-img {
                    width: 130px;
                    height: auto;
                    margin-right: 20px;
                    vertical-align: middle;
                    box-sizing: content-box;

                    &:hover {
                        border-bottom: 1px solid #2081fc;
                    }
                }

                .user-logo-look-forward {
                    display: inline-block;
                    font-size: 14px;
                    color: #ccc;
                    width: 130px;
                    height: 72px;
                    line-height: 72px;
                    text-align: center;
                    border: 1px solid #eee;
                    user-select: none;
                }
            }
        }
    }
}
.application-new-scene {
    font-family: PingFangSC-Medium;
    .application-scene-tab {
        height: 470px;
    }
    .application-scene-content {
        .top {
            height: 160px;
            border-bottom: none;
    
            .text-wrapper,
            .img-wrapper {
                display: inline-block;
                vertical-align: top;
            }
    
            .application-scene-banner {
                width: 280px;
                height: 158px;
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
            }
        }
        .bot {
            height: 148px;
            font-family: PingFangSC-Medium;
            font-size: 22px;
            color: #000000;
            letter-spacing: 0;
            line-height: 38px;
            font-weight: 500;
            .title {
                margin: 0px 0 20px
            }
            border-bottom: 1px solid #eee;
        }

    }
    .practice {
        .title {
            display: block;
            font-size: 22px;
            line-height: 38px;
            margin: 10px 0 10px;
            font-weight: 500;
        }
        a {
            display: inline-block;
            margin-top: 10px;
            font-size: 16px;
            padding: 10px 14px;
            color: #1A73E8;
            line-height: 22px;
            font-weight: 400;
            border: 1px solid rgba(26,115,232,1);
        }

        .desc {
            font-family: PingFangSC-Regular;
            display: inline-block;
            font-size: 14px;
            line-height: 24px;
            width: 580px;
            padding-right: 70px;
            box-sizing: content-box;
            color: #000000;
        }
    }
}

.application-wx-scene {
    display: flex;
    justify-content: space-between;
    .application-scene-content {
        width: 100%;

        .application-scene-item {
            display: none;

            &:first-child {
                display: block;
            }
        }

        .top {
            display: flex;
            justify-content: space-between;
            .desc {
                width: auto;
            }
        }
    }
}

.retail-func {
    &-tab {
        display: flex;
        justify-content: center;
        &-item {
            width: 180px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            font-size: 18px;
            color: #666;
            margin-right: 20px;
            cursor: pointer;
            &:last-child {
                margin-right: 0;
            }
            &.active {
                color: #036FE2;
            }
        }
        margin-bottom: 21px;
    }
    &-content {
        display: flex;
        height: 516px;
        &-img {
            width: 826px;
        }
        &-info {
            width: 345px;
            border: 1px solid #FAFAFA;
            padding-top: 24px;
            padding-left: 21px;
            position: relative;
            .info-result {
                font-size: 16px;
                color: #333333;
                line-height: 24px;
                font-weight: 600;
            }
            .info-doc {
                font-size: 14px;
                color: #036FE2;
                line-height: 30px;
                font-weight: 400;
                position: absolute;
                bottom: 0;
                left: 21px;
            }
            .info-content {
                padding-top: 20px;
                font-size: 14px;
                font-weight: 400;
                max-height: 425px;
                overflow: hidden;
                .info-0 {
                    position: relative;
                    &::before {
                        content: '';
                        position: absolute;
                        width: 4px;
                        height: 4px;
                        background: #ccc;
                        border-radius: 50%;
                        top: calc(50% - 2px);
                        left: -8px;
                    }
                }
                .info-block {
                    margin-bottom: 20px;
                    padding-left: 8px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
                .info-single {
                    margin-bottom: 12px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
                .info-key {
                    width: 114px;
                    display: inline-block;
                    color: #666;
                }
                .info-value {
                    width: calc(100% - 114px);
                    display: inline-block;
                    color: #000;
                }
            }
        }
    }
}