.start-use-related_shading {
    background-repeat: no-repeat;
    background-position: bottom center;
    background-image: url(../../../assets/shading.png), linear-gradient(0deg, #f5f5f5, rgba(255, 255, 255, 0));
    padding-top: 40px;
    padding-bottom: 60px;
}

.start-use-related_wrap {
    display: flex;
    justify-content: space-between;
}

.start-use {
    flex: 1;
    &_title {
        font-size: 40px;
        letter-spacing: 4px;
        margin-bottom: 16px;
        padding-top: 100px;
        font-weight: 500;
    }
    &_easydl-title {
        font-size: 40px;
        letter-spacing: 4px;
        margin-bottom: 16px;
        padding-top: 66px;
        font-weight: 500;
    }
    &_desc {
        font-size: 18px;
        color: #6b6b6b;
        letter-spacing: 2.5px;
        margin-bottom: 40px;
    }
    &_descs {
        font-size: 18px;
        color: #6b6b6b;
        letter-spacing: 2.5px;
        line-height: 32px;

        &_label {
            color: #000000;
            font-weight: 400;
        }
        &:last-child {
            margin-bottom: 32px;
        }
    }
}

.related {
    width: 510px;
    &_item {
        margin-bottom: 40px;
        display: block;
        &:hover {
            color: #036fe2;
            .tzfont {
                opacity: 1;
            }
        }
    }
    &_title {
        font-size: 16px;
        color: #999999;
        line-height: 24px;
        margin-bottom: 20px;
        padding-top: 66px;
    }
    &_link {
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 15px;
        font-weight: 500;
        & > i {
            font-size: 12px;
            opacity: 0;
        }
    }
    &_desc {
        font-size: 14px;
        color: #666666;
        line-height: 24px;
    }
}

.bml-startUse {
    padding-top: 140px;
    &-title {
        padding-bottom: 46px;
        font-size: 40px;
        text-align: center;
    }
    &-button {
        padding-bottom: 140px;
    }
}

.bml-start-use {
    padding: 100px 0;
    display: flex;
    justify-content: space-between;
    width: 1200px;
    margin: 0 auto;

    .title {
        margin-bottom: 16px;
        font-family: PingFangSC-Medium;
        font-size: 40px;
        color: #000000;
        letter-spacing: 4px;
        font-weight: 500;
    }

    .desc {
        margin-bottom: 40px;
        font-size: 18px;
        color: #666;
        letter-spacing: 2.5px;
        line-height: 26px;
        font-weight: 400;
    }

    .left,
    .right {
        flex: 1;
    }

    .sub-title {
        margin-bottom: 20px;
        font-size: 16px;
        color: #999999;
    }

    .product-name {
        font-size: 20px;
        margin-bottom: 14px;
        color: #000000;
        letter-spacing: 0;
        line-height: 30px;
        font-weight: 600;
    }

    .product-desc {
        font-size: 14px;
        color: #666666;
        text-align: justify;
        line-height: 24px;
        font-weight: 400;
    }
}
