.consoleModal {
    max-width: 852px;
    display: flex;
    margin: -20px 0;
    padding-top: 30px;

    .consoleModal-main {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }
    .section {
        display: flex;
        flex-direction: column;

        .title {
            font-size: 14px;
            color: #000000;
            line-height: 22px;
            font-weight: 500;
            margin-bottom: 14px;
        }

        &.left {
            flex: 1;
        }

        &.right {
        }

        .group-row {
            display: flex;
            margin-bottom: 14px;
        }
    }

    .section + .section {
        margin-left: 30px;
    }

    .group {
        font-size: 12px;

        &_title {
            display: flex;
            color: #666;
            margin-bottom: 8px;
            align-items: center;
            line-height: 20px;
        }
        .wenxin {
            margin-left: 8px;
            padding: 0 8px;
            border-radius: 2px;
            background: #2468F2;
            line-height: 20px;
            color: #ffffff;
        }

        &_head {
            .circle {
                display: inline-block;
                border-radius: 50%;
                width: 6px;
                height: 6px;
                margin-right: 8px;
                opacity: 0.6;
            }
        }

        &_body {
            display: flex;
        }
        &_col {
            display: flex;
            flex-direction: column;
        }
    }

    .group_col + .group_col,
    .group + .group {
        margin-left: 14px;
    }

    .group-item {
        display: flex;
        position: relative;
        align-items: center;
        width: 156px;
        height: 56px;
        padding: 16px 14px;
        background: rgba(249, 249, 249, 0.8);
        border: 1px solid #eeeeee;
        box-sizing: border-box;
        white-space: nowrap;
        overflow: hidden;

        &_icon {
            margin-right: 8px;
            width: 24px;
            height: 24px;
        }
        .newtag {
            &::after {
                content: 'new';
                background-color: red;
                color: #fff;
                position: absolute;
                top: -1px;
                right: -12px;
                transform: rotate(45deg);
                padding: 1px 8px;
            }
        }

        &:hover {
            background: #ffffff;
            box-shadow: 0 2px 10px 0 #dbdbdb;
        }
    }

    .group-item + .group-item {
        margin-top: 16px;
    }
}

.modal.modal {
    padding: 30px 40px;

    .modal-container {
        padding: 0;
    }
}
