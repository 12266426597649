@basic_blue: #036fe2;
@basic_blue_hover: #1c88fc;

.tech_tab {
    text-align: center;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #d8d8d8;
    margin-bottom: 50px;

    .tech_item_tab {
        line-height: 60px;
        text-align: center;
        font-size: 20px;
        color: #666666;
        letter-spacing: 0;
        cursor: pointer;
        margin-right: 60px;
        padding: 0 20px;
        border-bottom: 2px solid transparent;
        &.active {
            color: #1a73e8;
            border-color: #1a73e8;
        }
        &:last-child {
            margin-right: 0;
        }
    }
}
.tech_item_new {
    .tech_item_left {
        width: 501px;
        & > img {
            display: block;
            width: 501px;
            height: 394px;
            object-fit: cover;
        }
    }
    .tech_item_right {
        font-family: PingFangSC-Regular;
        padding: 60px 54px 60px 78px;
        height: 394px;
        p {
            line-height: 24px;
        }
    }
}

.tech_item {
    font-size: 14px;
    display: flex;
    overflow: hidden;
    background: #ffffff;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);

    &_left {
        width: 510px;
        & > img {
            display: block;
            width: 510px;
            height: 400px;
            object-fit: cover;
        }
    }
    &_right {
        padding: 70px 50px 50px 80px;
        height: 400px;
        display: flex;
        flex-flow: column nowrap;
        position: relative;
        background: url(../../../assets/bml/scene-shading.png) no-repeat bottom right / 50%;
        .sub_title {
            font-size: 24px;
            color: #000000;
            letter-spacing: 0;
            margin-bottom: 30px;
            margin-top: 0;
        }

        .sub_desc {
            color: #000000;
            text-align: justify;
            line-height: 26px;
            flex-grow: 1;
        }
        .sub_tag {
            display: block;
            margin-top: 30px;
            text-align: justify;
            font-size: 14px;
            color: #666666;
            line-height: 22px;
            font-weight: 400;

        }

        .use_case {
            &_wrapper .logo_img {
                width: 130px;
                height: 72px;
                margin-right: 20px;
                vertical-align: middle;
            }
            .look_forward {
                font-size: 14px;
                color: #ccc;
                width: 130px;
                height: 72px;
                line-height: 72px;
                text-align: center;
                border: 1px solid #eee;
                user-select: none;
            }
            .look_forward_a {
                display: inline-block;
                text-align: center;
                width: 92px;
                height: 42px;
                line-height: 42px;
                border: 1px solid rgba(26,115,232,1);
                font-size: 16px;
                color: #1A73E8;
                letter-spacing: 0;
                font-weight: 400;
            }
        }
    }
}

.codelab_item {
    font-size: 14px;
    display: flex;
    background: #ffffff;
    padding: 30px;
    height: 453px;
    text-align: justify;
    letter-spacing: 0;
    .codelab_left {
        margin-right: 30px;
        & > img {
            display: block;
            width: 580px;
            height: 369px;
            object-fit: cover;
        }
    }

    .codelab_right {
        padding: 15px 15px 15px 0;
        display: flex;
        flex-flow: column nowrap;
        position: relative;
        color: #000000;

        &-title {
            font-size: 24px;
        }
        &-sub-title {
            font-size: 14px;
        }
        &-support {
            margin-top: 20px;
            .support-item {
                display: flex;
                .support-img {
                    width: 14px;
                    height: 14px;
                    margin-right: 12px;
                    align-self: center;
                }
                .support-info {
                    font-size: 14px;
                    color: #666666;
                    line-height: 26px;
                    & > a {
                        color: #1a73e8;
                    }
                }
            }
            .email {
                line-height: 30px;
                color: #1a73e8;
                padding-left: 26px;
            }
        }
        &-info {
            margin-top: 40px;
            .info-item {
                display: flex;
                font-size: 12px;
                color: #666666;
                line-height: 20px;
                margin-bottom: 5px;
                .info-key {
                    width: 100px;
                }
            }
        }
        &-download {
            display: flex;
            margin-top: 20px;
            position: relative;
            .download-btn {
                color: #fff;
                min-width: 120px;
                padding: 0 22px;
                height: 40px;
                line-height: 40px;
                text-align: center;
                cursor: pointer;
                background-image: linear-gradient(123deg, #38abf5 0%, #1a73e8 100%);
            }

            .download-select {
                display: none;
                width: 40px;
                height: 40px;
                background: #1a73e8;
                margin-left: 5px;
                cursor: pointer;

                .triangle {
                    margin: 15px auto;
                    width: 0;
                    height: 0;
                    border-right: 6px solid transparent;
                    border-left: 6px solid transparent;
                    border-top: 8.5px solid white;
                }
                .rotate {
                    transform: rotate(180deg);
                }
                .select-container {
                    position: absolute;
                    display: none;
                    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
                    top: 50px;
                    left: 0;
                    .select-item {
                        height: 32px;
                        width: 400px;
                        padding: 5px 12px;
                        color: #333333;
                        cursor: pointer;
                        &:hover {
                            color: #1a73e8;
                        }
                    }
                    .active {
                        color: #1a73e8;
                    }

                    &.hide {
                        display: block;
                    }
                }
                &.hide {
                    display: block;
                }
            }
            .hide {
                display: block;
            }

            .more-version {
                margin-left: 10px;
                font-size: 14px;
                color: #1a73e8;
                line-height: 40px;
            }
        }
    }
}
.tech_tab_f {
    justify-content: space-between;
    .tech_item_tab {
        flex: 1;
        margin-right: 0px;
    }
}
.tech_item_f {
    display: flex;
    flex-flow: row nowrap;
    &_left {
        width: 645px;
        height: 390px;
    }
    &_right {
        flex: 1;
        display: flex;
        flex-flow: column nowrap;
        padding-left: 69px;
        justify-content: center;
        font-size: 14px;
        color: #000000;
        &_content {
            min-height: 84px;
            display: flex;
            flex-flow: column nowrap;
            justify-content: center;
            border-top: 1px solid #DADCDF;
            img {
                transform: rotateX(180deg);
                width: 16px;
                height: 12px;
            }
            &:first-child {
                border-top: none;
            }
            &>div {
                display: flex;
                flex-flow: row nowrap;
                justify-content: space-between;
                align-items: center;
                font-size: 16px;
            }
        }
        .active_f {
            box-sizing: content-box;
            padding-top: 30px;
            padding-bottom: 30px;
            &>div {
                font-size: 24px;
                font-weight: 500;
                img {
                    transform: none; 
                }
            }
            p {
                margin-top: 16px;
                line-height: 26px;
            }
        }
    }
}
