.paddle_column {
    position: absolute;
    width: 100%;
    height: 60px;
    line-height: 60px;
    background: #FFFFFF;
    box-shadow: 0 2px 6px 0 rgba(0,0,0,0.05);
    font-family: PingFangSC-Regular;
    font-size: 18px;
    color: #000000;
    letter-spacing: 0;
    text-align: center;
    font-weight: 400;
    z-index: 900;
    overflow: hidden;

    &::before {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        height: 100%;
        display: block;
        content: '';
        backdrop-filter: blur(4px);
    }

    .column-content {
        width: 1100px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .column-item {
            width: auto;

            &_name {
                display: inline-block;
                position: relative;
                cursor: pointer;
            }

            &_active {
                position: absolute;
                width: 100%;
                height: 2px;
                bottom: 1px;
                background-color:#036FE2;
            }
        }
    }
}

.paddle_fixd {
    position: fixed !important;
    top: 60px;
}
.paddle_timeline {
    padding: 0 51px;
    height: 100%;
    .paddle_timeline_box {
        position: relative;
    }
    .paddle_timeline_box_content {
        overflow: hidden;
    }
    .paddle_timeline_tolink {
        margin-top: 51px;
        text-align: center;
        font-family: PingFangSC-Regular;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 24px;
        font-weight: 400;
        &>a {
            color: #036FE2;
        }
    }
    .timeline-content {
        display: flex;
        padding-left: 43px;
        .timeline-content-card {
            .content-card-circle {
                position: absolute;
                width: 40px;
                height: 40px;
                border-radius: 50%;
                top: 37%;
                left: 50%;
                z-index: 8;
                transform: translate(-50%, -1px);
                &::before {
                    position: absolute;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -38%);
                    content: '';
                    display: inline-block;
                    width: 9px;
                    height: 9px;
                    background-color: #fff;
                    background-size: cover;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-image: url(assets/paddle/timeline.jpg);
                }
            }
            position: relative;
            display: inline-block;
            font-family: PingFangSC-Regular;
            font-size: 12px;
            color: #666666;
            letter-spacing: 0;
            text-align: center;
            line-height: 24px;
            font-weight: 400;
            margin-right: 60px;
            width: 210px;
            &>span {
                font-size: 16px;
                color: #999999;
                line-height: 26px;
                font-weight: 400;
            }
            .content-card-edition {
                font-size: 22px;
                color: #000000;
                line-height: 30px;
                font-weight: 600;
                margin-top: 20px;
                margin-bottom: 142px;
            }
            .content-card-desc {
                text-align: justify;
                &>span {
                    display: block;
                }
            }
        }
        .timeline-content-activate {
            .content-card-circle { 
                &::before {
                    width: 34px;
                    height: 34px;
                    top: 0;
                    left: 0;
                    transform: translate(3px, 3px);
                    background-image: url(assets/paddle/timeline-activate.png);
                }
            }
            .content-card-edition {
                color: #036FE2;
            }
        }
    }
    .timeline-console {
        position: absolute;
        width: 100%;
        top: 42%;
        &::before {
            display: block;
            content: '';
            width: 100%;
            height: 1px;
            background: #d4d4d4;
            position: absolute;
            top: 0;
            bottom: 0;
            z-index: -1;
            margin: auto;
        }
        .timeline-console-left, .timeline-console-right {
            display: inline-block;
            width: 36px;
            height: 36px;
            border: 1px solid #CCCCCC;
            border-radius: 50%;
            position: absolute;
            top: -18px;
            text-align: center;
            line-height: 43px;
            &:hover {
                background-color: #036FE2;
                border: none;
                color: #fff;
            }
            &::before {
                display: inline-block;
                content: '';
                width: 8px;
                height: 8px;
                border: 1px solid #CCCCCC;
            }
        }
        .timeline-console-left {
            left: -51px;
            &::before {
                border-top: none;
                border-right: none;
                transform: rotateZ(45deg) translate(2px, -2px);
            }
        }
        .timeline-console-right {
            right: -51px;
            &::before {
                border-bottom: none;
                border-left: none;
                transform: rotateZ(45deg) translate(-2px, 2px);
            }
        }
    }
}
.paddle_card_list_box {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: stretch;
    .paddle_box {
        position: relative;
        width: 380px;
        border: 1px solid #E1E1E1;
        padding: 30px 50px 40px;
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: #000000;
        letter-spacing: 0;
        line-height: 24px;
        font-weight: 400;
        .paddle_box_title {
            font-size: 22px;
            line-height: 38px;
            font-weight: 500;
        }
        .paddle_box_desc {
            margin-top: 10px;
            margin-bottom: 34px;
        }
        &>a {
            position: absolute;
            bottom: 40px;
            font-size: 14px;
            color: #036FE2;
            line-height: 24px;
            font-weight: 400;
        }
    }
}