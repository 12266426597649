.duty {
    max-width: 960px;
    margin: 50px auto;
    font-size: 14px;
    line-height: 1.75;

    h1 {
        font-size: 1.57142857em;
        text-align: center;
        font-weight: bold;
        margin: 20px 0;
    }

    h2 {
        font-size: 1.28571429em;
        font-weight: bold;
        margin: 20px 0;
    }

    h3 {
        font-size: 1.14285714em;
        font-weight: bold;
        margin: 20px 0;
    }

    p {
        text-indent: 2em;
        margin: 20px 0;
    }

    ul {
        list-style: disc;
        margin-left: 4em;
    }
}
