body {
    background-color: #fafafc;
}
.pof-image {
    width: 1200px;
}
.pov-footer {
    text-align: center;
    padding-top: 60px;
    & > a {
        font-size: 20px;
        &:hover {
            color: #1a73e8;
        }
        & > i {
            font-size: 12px;
            padding-left: 8px;
        }
    }
}
.bml-shading {
    background-image: url(../assets/shading.png), linear-gradient(0deg, #f5f5f5, rgba(255, 255, 255, 0));
    background-repeat: no-repeat;
    background-position: bottom center;
    overflow: hidden;
}
.bml-banner_content {
    // 这里考虑了下边 bml-ad 的高度
    transform: translateY(calc(-50% - 35px)) !important;
    .bml-banner_detail {
        line-height: 20px;
        height: 40px;
    }
}

.bml-banner_tag {
    background: rgba(178, 208, 248, 0.3);
    height: 27px;
    margin: 0 auto 40px;
    padding: 5px 12px;
    font-size: 12px;
    color: #ffffff;
    letter-spacing: 1px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    > img {
        width: 10px;
        height: 13px;
        margin-right: 6px;
    }
}
.suffix {
    position: relative;
    display: inline-block;
    background: #FF3A3A;
    // width: 58px;
    padding: 0 6px;
    height: 20px;
    line-height: 20px;
    font-size: 12px;
    color: #FFFFFF;
    letter-spacing: 0;
    text-align: center;
    line-height: 20px;
    font-weight: 500;
    margin-left: 8px;
    &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: -8px;
        width: 0;
        height: 0;
        border-bottom: 7px solid red;
        border-left: 10px solid transparent;
    }
}
.banner-bg {
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    .slide {
        width: 900px;
        display: block;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        &-container {
            padding-top: 82px;
            color: #fff;
        }
        &-title {
            font-size: 34px;
            line-height: 38px;
            color: #ffffff;
            margin-bottom: 16px;
        }
        &-desc {
            font-size: 18px;
            line-height: 20px;
            color: #ffffff;
            margin-bottom: 36px;
        }
    }
    .slide-center {
        .slide-container {
            text-align: center;
        }
        .slide-title {
            text-align: center;
        }
        .slide-desc {
            text-align: center;
        }
    }
    .slide-left {
        .slide-container {
            padding-left: 90px;
        }
    }
    .lite-pagination-bullet {
        cursor: pointer;
        width: 40px;
        height: 18px;
        display: inline-block;
        background-color: rgba(255, 255, 255, 0.3);
        opacity: 0.3;
        padding: 8px 5px;
        background-clip: content-box;
        &-active {
            background-color: #fff;
            opacity: 0.8;
        }
    }
    .lite-swiper-pagination {
        position: absolute;
        bottom: 86px !important;
        text-align: center;
        width: 100%;
        height: 19px;
        z-index: 10;
    }
}
.bml-cardad {
    position: relative;
}
.paddle_paddle {
    display: flex;
}