

.transition {
    position: fixed;
    top: 0;
    left: 50%;
    transition: all 0.3s ease-out;
    transform: translateX(-50%) translateY(-100%);
    z-index: 2000;
    &.visible {
        top: 5%;
        transform: translateX(-50%) translateY(0);
    }
}

.toast {
    display: flex;
    align-items: center;
    background: #fff;
    border-radius: 4px;
    padding: 38px 50px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    font-size: 42px;
    color: #000000;
    line-height: 60px;
}
.icon {
    height: 60px;
    width: 60px;
}
.children {
    padding-left: 18px;
}
