.alink {
    text-decoration: none;
    &-grounp {
        &.alink-grounp-center {
            text-align: center;
        }
        &.alink-grounp-left {
            text-align: left;
        }
    }
}
.gold {
    color: white;
}
